import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Redirect, useLocation } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";

export const memberIsActive = (membership) => {
  if (membership.data.Status === "expired") return false;
  if (membership.data.Status === "inactive") return false;
  if (membership.data.Expires.unix < Math.floor(Date.now() / 1000))
    return false;
  return true;
};

const MemberRequired = ({ children }) => {
  const location = useLocation();
  const [user] = useRest("User:get");
  const [memberShip, setMemberShip] = useState(null);

  useEffect(() => {
    if (user !== null) {
      rest("Membership/@fanclub/User/@")
        .then((data) => {
          setMemberShip(memberIsActive(data));
        })
        .catch(() => {
          setMemberShip(false);
        });
    }
  }, [user]);

  if (memberShip === null) return false;

  if (memberShip) {
    return <>{children}</>;
  }

  return <Redirect from={location.pathname} to={`/subscribe`} />;
};

export default MemberRequired;
