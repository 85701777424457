import React, { useState, useEffect } from "react";
import { getLocale } from "@karpeleslab/klbfw";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getPrefix } from "@karpeleslab/klbfw";
import clsx from "clsx";

// component
import Input from "components/Forms/Input";
import TextArea from "components/Forms/TextArea";
import FadeUp from "components/FadeUp/FadeUp";
import { error } from "components/Toast/Toast";
import HelmetComponents from "components/Helmet/Helmet";

// utils
import { validateEmail } from "utils/Validation";

// style

// img
import iconReturn from "assets/img/icon-link-return.svg";
import iconAgree from "assets/img/icon-agree.svg";

const Contact = () => {
  const { t } = useTranslation();
  const defaultLng = getLocale();

  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [agree, setAgree] = useState(false);
  const [enable, setEnable] = useState(true);
  const [confirm, setConfirm] = useState(false);

  // send
  const [sendComp, setSendComp] = useState(false);

  useEffect(() => {
    if (
      !validateEmail(email) ||
      !agree ||
      !name.trim() ||
      !email.trim() ||
      !content.trim()
    ) {
      setEnable(true);
      return;
    }
    setEnable(false);
  }, [email, name, content, agree]);

  const send = () => {
    const query = {
      Email: email,
      Name: name,
      To: "@support",
      Subject: subject,
      Message: content,
    };

    rest("Support/Ticket", "POST", query)
      .then(() => {
        setSendComp(true);
      })
      .catch((err) => {
        error(t(`error_${err.token}`), false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <HelmetComponents>
        <title>
          {t("contact_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/contact`}
        />
        <meta
          property="og:title"
          content={`${t("contact_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/contact`}
        />
      </HelmetComponents>
      <div
        className={clsx("c-block c-block--high", {
          ["is-en"]: defaultLng === "en-US",
        })}
      >
        <div className="c-box-popup">
          <Link
            className="c-box-popup__return"
            to="/"
            style={{
              backgroundImage: `url(${iconReturn})`,
            }}
          >
            <p className="c-box-popup__return-txt">
              <em>{t("contact_back_top")}</em>
            </p>
          </Link>
          {sendComp && (
            <div className="mw_wp_form mw_wp_form_complete">
              <p className="c-ttl-4">{t("contact_comp_title")}</p>
              <br />
              <span
                dangerouslySetInnerHTML={{ __html: t("contact_comp_text") }}
              />
              <div className="c-btn-3 mgt-pc--70 mgt-sp--70 js-language-jp">
                <Link to="/" className="c-btn-3__item">
                  {t("contact_comp_btn")}
                </Link>
              </div>
            </div>
          )}
          {!sendComp && (
            <>
              <div
                id="mw_wp_form_mw-wp-form-46"
                className="mw_wp_form mw_wp_form_input  "
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    send();
                  }}
                >
                  <section className="c-section-4">
                    <h2 className="c-ttl-2">
                      <span className="c-ttl-2__main">
                        {t("contact_title")}
                      </span>

                      <FadeUp>
                        <span className="c-ttl-2__sub">
                          <span>{t("contact_sub_text")}</span>
                        </span>
                      </FadeUp>
                      {defaultLng !== "en-US" && (
                        <FadeUp>
                          <span className="c-ttl-2__sub">
                            <span>{t("contact_sub_text_2")}</span>
                          </span>
                        </FadeUp>
                      )}
                    </h2>
                    <div className="c-section-4__inner">
                      <Input
                        label={t("contact_input_name")}
                        required={true}
                        name="name"
                        value={name}
                        handleChange={setName}
                        confirm={confirm}
                      />
                      <Input
                        label={t("contact_input_email")}
                        required={true}
                        name="email"
                        type="email"
                        value={email}
                        handleChange={setEmail}
                        addClass="mgt-pc--30 mgt-sp--40"
                        confirm={confirm}
                      />
                      <Input
                        label={t("contact_input_subject")}
                        required={true}
                        name="subject"
                        value={subject}
                        handleChange={setSubject}
                        addClass="mgt-pc--30 mgt-sp--40"
                        confirm={confirm}
                      />
                      <TextArea
                        label={t("contact_input_textarea")}
                        required={true}
                        name="content"
                        addClass="mgt-pc--30 mgt-sp--40"
                        value={content}
                        handleChange={setContent}
                        confirm={confirm}
                      />
                      {!confirm && (
                        <>
                          <div className="c-checkbox">
                            <label className="c-checkbox__label">
                              <input
                                type="checkbox"
                                name="agree"
                                className="c-checkbox__input"
                                onChange={() => {
                                  setAgree(!agree);
                                }}
                              />
                              <span className="c-checkbox__txt">
                                <span>
                                  <Link to="/pages/privacy">
                                    {t("contact_agree")}
                                  </Link>
                                </span>
                                {agree && (
                                  <span className="c-checkbox__txt_agree">
                                    <img src={iconAgree} alt="" />
                                  </span>
                                )}
                              </span>
                            </label>
                          </div>
                          <div
                            id="confirm1"
                            className="c-btn-3 mgt-pc--70 mgt-sp--70 js-language-jp"
                          >
                            <input
                              type="button"
                              name="submitConfirm"
                              value={t("contact_submit")}
                              className="c-btn-3__item"
                              disabled={enable}
                              onClick={() => {
                                setConfirm(true);
                              }}
                            />
                          </div>
                        </>
                      )}
                      {confirm && (
                        <>
                          <div
                            id="confirm1"
                            className="c-btn-3 mgt-pc--70 mgt-sp--70 js-language-jp"
                          >
                            <input
                              type="button"
                              onClick={() => {
                                setConfirm(false);
                              }}
                              name="submitBack"
                              value="戻る"
                              className="c-btn-3__item mgb-pc--20 mgb-sp--20"
                            />

                            <input
                              type="submit"
                              name="submitButton"
                              value="送信する"
                              className="c-btn-3__item"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
