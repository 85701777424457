import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams, Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getPrefix } from "@karpeleslab/klbfw";

// material ui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// component
import HelmetComponents from "components/Helmet/Helmet";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";

// styles
import styles from "../MyPage.module.scss";

// const

const History = () => {
  const { t } = useTranslation();
  const { pageNum } = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );
  const [order] = useRest(
    "Order",
    {
      page_no: currentPage,
      results_per_page: 10,
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <>
      <HelmetComponents>
        <title>
          {t("maypage_tab_history")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("maypage_tab_history")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div>
        <p className={styles["mypage-back"]}>
          <Link className={styles["mypage-back-link"]} to="/mypage">
            {t("mypage_back")}
          </Link>
        </p>
        <h3 className="c-ttl-5" style={{ marginBottom: "20px" }}>
          {t("maypage_tab_history")}
        </h3>
        {order === null && <Loading />}
        {order !== null && order?.data.length === 0 && (
          <p className={styles["mypage__notfound"]}>
            {t("mypage_history_no_order_text")}
          </p>
        )}
        {order !== null && order?.data.length !== 0 && (
          <TableContainer>
            <Table className={styles["history-table"]}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("mypage_history_label_no")}</TableCell>
                  <TableCell>{t("mypage_history_label_date")}</TableCell>
                  <TableCell>{t("mypage_history_label_status")}</TableCell>
                  <TableCell>{t("mypage_history_label_price")}</TableCell>
                  <TableCell>{t("mypage_history_label_pdf")}</TableCell>
                  <TableCell align="center">
                    {t("mypage_history_label_detail")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.data.map((item, index) => {
                  const invoiceDate = moment(
                    parseInt(item.Created?.unixms)
                  ).format("YYYY-MM-DD HH:mm:ss");
                  const invoiceNum = item.Invoice_Number;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {invoiceNum !== null ? invoiceNum : "-"}{" "}
                      </TableCell>
                      <TableCell>
                        {invoiceDate !== "Invalid date" ? invoiceDate : "-"}
                      </TableCell>
                      <TableCell>{t(`order_status_${item.Status}`)}</TableCell>
                      <TableCell>{item.Total_Vat.display}</TableCell>
                      <TableCell>
                        {item.Invoice_Url === void 0 ? (
                          "-"
                        ) : (
                          <a
                            className={styles["mypage-history-link"]}
                            href={item.Invoice_Url}
                          >
                            PDF
                          </a>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          className={styles["mypage-history-link"]}
                          to={`/order/${item.Order__}?type=history`}
                        >
                          {t("mypage_history_label_detail")}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {order !== null && order?.data.length !== 0 && (
          <Pagination
            setCurrentPage={setCurrentPage}
            defaultPage={currentPage}
            count={order?.paging.page_max}
            path="mypage/history"
          />
        )}
      </div>
    </>
  );
};

export default History;
