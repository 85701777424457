import React from "react";
import { useTranslation } from "react-i18next";
import { TwitterTimelineEmbed } from "react-twitter-embed";

// component
import FadeUp from "components/FadeUp/FadeUp";

// style

// img

const Twitter = () => {
  const { t } = useTranslation();
  return (
    <FadeUp>
      <section className="c-section-3 js-fadeup is-fadeup">
        <h3 className="c-ttl-3">{t("works_twitter_title")}</h3>

        <div
          className="c-section-3__content js-height-1"
          style={{
            height: "610px",
          }}
        >
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="hayayu1231"
            autoHeight={true}
            className="c-section-3__content-tw"
          />
        </div>
      </section>
    </FadeUp>
  );
};

export default Twitter;
