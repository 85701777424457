import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { success } from "components/Toast/Toast";

// component

// style

// img

const Share = ({ article }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const ToastSuccess = () => {
    success(t("success_copy"), false, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  return (
    <>
      <div className="c-article__share">
        <span className="c-article__share-head">{t("common_share_title")}</span>
        <ul className="c-article__list">
          <li className="c-article__item">
            <a
              href={`http://www.facebook.com/share.php?u=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}`}
              target="_blank"
              className="c-article__link c-article__link--facebook"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.23 23.56">
                <path d="M7.94,23.56V12.81h3.61l.54-4.19H7.94V6c0-1.21.34-2,2.08-2h2.21V.16A30.35,30.35,0,0,0,9,0C5.81,0,3.62,2,3.62,5.54V8.62H0v4.19H3.62V23.56Z" />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <a
              href={`https://twitter.com/share?url=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}&amp;text=${location.pathname}`}
              target="_blank"
              className="c-article__link c-article__link--twitter"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.16 19.63">
                <path
                  className="cls-1"
                  d="M24.16,2.32a10.11,10.11,0,0,1-2.85.78A5,5,0,0,0,23.49.36a9.84,9.84,0,0,1-3.15,1.2A5,5,0,0,0,11.77,5a4.66,4.66,0,0,0,.13,1.13A14.1,14.1,0,0,1,1.68.9,5,5,0,0,0,1,3.4a5,5,0,0,0,2.2,4.12A4.88,4.88,0,0,1,1,6.9V7a5,5,0,0,0,4,4.86,4.85,4.85,0,0,1-2.24.09,4.93,4.93,0,0,0,4.62,3.44,9.91,9.91,0,0,1-6.15,2.12A9.23,9.23,0,0,1,0,17.4a14,14,0,0,0,7.6,2.23A14,14,0,0,0,21.7,5.53c0-.22,0-.43,0-.65a9.74,9.74,0,0,0,2.48-2.56"
                />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=h${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}&amp;title=${location.pathname}`}
              target="_blank"
              className="c-article__link c-article__link--linkedin"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.46 22.15">
                <path
                  className="cls-1"
                  d="M5.09,2.4A2.39,2.39,0,0,1,2.5,4.8,2.36,2.36,0,0,1,0,2.4,2.38,2.38,0,0,1,2.56,0,2.38,2.38,0,0,1,5.09,2.4Zm-5,19.75V6.7h4.8V22.15Z"
                />
                <path
                  className="cls-1"
                  d="M7.8,11.63c0-1.93-.06-3.54-.12-4.93h4.17l.22,2.15h.09a5.53,5.53,0,0,1,4.77-2.5c3.16,0,5.53,2.12,5.53,6.67v9.13h-4.8V13.58c0-2-.7-3.34-2.43-3.34A2.64,2.64,0,0,0,12.76,12a3.56,3.56,0,0,0-.16,1.2v8.91H7.8Z"
                />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <a
              href={`http://service.weibo.com/share/share.php?url=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}&amp;title=${location.pathname}`}
              target="_blank"
              className="c-article__link c-article__link--weibo"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.08 23.56">
                <path
                  className="cls-1"
                  d="M27.1,2.54A7.7,7.7,0,0,0,19.77.17h0a1.12,1.12,0,0,0-.86,1.32,1.12,1.12,0,0,0,1.33.86,5.47,5.47,0,0,1,6.35,7h0a1.11,1.11,0,0,0,.72,1.4h0a1.11,1.11,0,0,0,1.4-.72h0A7.69,7.69,0,0,0,27.1,2.54"
                />
                <path
                  className="cls-1"
                  d="M24.17,5.19A3.76,3.76,0,0,0,20.6,4a1,1,0,0,0,.34,1.89H21a1.83,1.83,0,0,1,2.12,2.36h0A1,1,0,0,0,25,8.86a3.74,3.74,0,0,0-.78-3.67"
                />
                <path
                  className="cls-1"
                  d="M21.85,11.34c-.41-.12-.69-.2-.48-.74a3,3,0,0,0,0-2.9c-.94-1.35-3.53-1.27-6.49,0,0,0-.93.4-.69-.33.46-1.47.39-2.69-.32-3.4C12.27,2.33,8,4,4.34,7.66,1.59,10.4,0,13.31,0,15.83c0,4.81,6.17,7.73,12.2,7.73,7.91,0,13.17-4.6,13.17-8.24C25.37,13.11,23.52,11.86,21.85,11.34ZM2.93,17c-.31-3.16,3.34-6.1,8.16-6.58s9,1.7,9.28,4.86S17,21.36,12.22,21.84,3.25,20.13,2.93,17Z"
                />
                <path
                  className="cls-1"
                  d="M12.7,12.82a5.25,5.25,0,0,0-5.88,2.57A3.58,3.58,0,0,0,9.1,20.48a5.2,5.2,0,0,0,6.21-2.64A3.59,3.59,0,0,0,12.7,12.82ZM11,18.08a1.84,1.84,0,0,1-2.22.73,1.29,1.29,0,0,1-.49-1.93,1.84,1.84,0,0,1,2.17-.73A1.28,1.28,0,0,1,11,18.08Zm1.53-2a.69.69,0,0,1-.83.31.48.48,0,0,1-.21-.73.67.67,0,0,1,.81-.3A.48.48,0,0,1,12.48,16.11Z"
                />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <a
              href={`https://www.tumblr.com/widgets/share/tool?posttype=link&title=Buttons%20|%20Tumblr&content=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}&tags=tumblelog,%20blog,%20tumblog,%20tumbler,%20tumblr,%20tlog,%20microblog&canonicalUrl=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}&shareSource=tumblr_share_button`}
              target="_blank"
              className="c-article__link c-article__link--tumblr"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.34 23.56">
                <path
                  className="cls-1"
                  d="M13.34,23.56H9.4c-3.54,0-6.18-1.82-6.18-6.18v-7H0V6.61A7,7,0,0,0,5.2,0H8.88V6h4.29v4.39H8.88v6.08c0,1.83.92,2.46,2.38,2.46h2.08Z"
                />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <a
              href={`https://social-plugins.line.me/lineit/share?url=${article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink}`}
              target="_blank"
              className="c-article__link c-article__link--line"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24.78">
                <path
                  className="cls-1"
                  d="M26,10.55C26,4.73,20.17,0,13,0S0,4.73,0,10.55C0,15.77,4.63,20.14,10.87,21c.43.09,1,.28,1.15.64a2.62,2.62,0,0,1,0,1.18l-.18,1.11c-.06.33-.27,1.29,1.12.7S20.49,20.18,23.22,17h0A9.38,9.38,0,0,0,26,10.55ZM8.41,13.66a.25.25,0,0,1-.24.25H4.51a.25.25,0,0,1-.24-.25h0V8a.25.25,0,0,1,.24-.25h.93A.25.25,0,0,1,5.68,8v4.5H8.17a.25.25,0,0,1,.24.25Zm2.2,0a.25.25,0,0,1-.24.25H9.44a.25.25,0,0,1-.24-.25V8a.25.25,0,0,1,.24-.25h.93a.25.25,0,0,1,.24.25Zm6.28,0a.25.25,0,0,1-.25.25H15.58l-.06-.07-2.6-3.51v3.37a.25.25,0,0,1-.25.25h-.92a.25.25,0,0,1-.25-.25V8a.25.25,0,0,1,.25-.25h1.11l0,0,2.59,3.51V8a.25.25,0,0,1,.25-.25h.92a.25.25,0,0,1,.25.25Zm5-4.75a.25.25,0,0,1-.25.25H19.2v1h2.48a.25.25,0,0,1,.25.24v.93a.25.25,0,0,1-.25.24H19.2v1h2.48a.25.25,0,0,1,.25.25v.92a.25.25,0,0,1-.25.25H18a.25.25,0,0,1-.25-.25h0V8h0A.25.25,0,0,1,18,7.74h3.65a.25.25,0,0,1,.25.25Z"
                />
              </svg>
            </a>
          </li>
          <li className="c-article__item">
            <CopyToClipboard
              text={
                article.data.content_cms_entry_data.Content_Cms_Entry.ShortLink
              }
              onCopy={() => {
                ToastSuccess();
              }}
            >
              <button className="c-article__link c-article__link--clip js-copy-trigger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 23.51 23.56"
                >
                  <path
                    className="cls-1"
                    d="M13.49,17.49a5.29,5.29,0,0,1-.8.05,6.5,6.5,0,0,1-2.43-.45L10,17,7,20a2.57,2.57,0,0,1-1.8.74A2.4,2.4,0,0,1,3.48,20a2.46,2.46,0,0,1,0-3.43l3.24-3.24L9.09,11l.12-.11a.39.39,0,0,1,.1-.08,1.85,1.85,0,0,1,.27-.19l.09,0,.3-.14.12,0,.3-.08.14,0h.3a2.25,2.25,0,0,1,.85.16,2.49,2.49,0,0,1,1.38,1.29.43.43,0,0,0,0,.07.81.81,0,0,0,.31-.21L15.2,9.78a4.47,4.47,0,0,0-.65-.8,5,5,0,0,0-.8-.65l-.19-.13a5.33,5.33,0,0,0-.83-.41,5.33,5.33,0,0,0-1.91-.35,5.25,5.25,0,0,0-2.53.64,5.54,5.54,0,0,0-1.2.9L1.58,14.5A5.4,5.4,0,0,0,0,18.36a5.15,5.15,0,0,0,1.58,3.72A5.28,5.28,0,0,0,9,22l2.54-2.54,2-2h0Z"
                  />
                  <path
                    className="cls-1"
                    d="M21.92,1.47a5.29,5.29,0,0,0-7.39.07L12,4.08l-2,2h0A5.51,5.51,0,0,1,10.82,6a6.76,6.76,0,0,1,2.42.44l.26.11,3-3a2.54,2.54,0,0,1,1.79-.74A2.42,2.42,0,0,1,20,7l-3.23,3.24-2.34,2.34a2.44,2.44,0,0,1-1.61.71h-.13a2.46,2.46,0,0,1-.85-.16,2.4,2.4,0,0,1-.88-.56,2.43,2.43,0,0,1-.5-.73.35.35,0,0,0,0-.08.47.47,0,0,0-.39-.2.51.51,0,0,0-.34.14L8.3,13.08a.5.5,0,0,0-.07.6.61.61,0,0,1,.07.1,5.36,5.36,0,0,0,1.45,1.45l.19.12a5.62,5.62,0,0,0,1.81.69,5.86,5.86,0,0,0,.94.08,5.26,5.26,0,0,0,2.53-.65,4.87,4.87,0,0,0,1.19-.89l5.52-5.52a5.41,5.41,0,0,0,1.58-3.87A5.2,5.2,0,0,0,21.92,1.47Z"
                  />
                </svg>
              </button>
            </CopyToClipboard>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Share;
