import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import qs from "qs";

// material ui

// component
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Toast, { error } from "components/Toast/Toast";
import Button from "components/Btns/Btn";
import ReserveList from "components/List/ReserveList";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import BillingInputs from "components/Billing/BillingInputs";
import InputForm from "components/Forms/Input";

// style
import styles from "./Reserve.module.scss";

const Reserve = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [user] = useRest("User:get");
  const [event] = useRest(`Planner/Schedule/${slug}`);
  const [tickets, setTickets] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const hash = qs.parse(location.search.split("?")[1]);
  const [currentSeat, setCurrentSeat] = useState("");

  // load
  const [load, setLoad] = useState(false);

  // form
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [maxAmount, setMaxAmount] = useState(0);
  const [maxOut, setMaxOut] = useState(false);
  const [soldOut, setSoldOut] = useState(false);

  //max
  useEffect(() => {
    if (tickets !== null) {
      if (event?.data.Planner_Event.Max_Seat_Per_User !== null) {
        const max = parseInt(event.data.Planner_Event.Max_Seat_Per_User);
        if (max - tickets.length > 0) {
          setMaxAmount(max - tickets.length);
        } else {
          setMaxOut(true);
        }
      } else {
        setMaxAmount(
          parseInt(currentSeat[0].Capacity) - parseInt(currentSeat[0].Count) < 9
            ? parseInt(currentSeat[0].Capacity) - parseInt(currentSeat[0].Count)
            : 9
        );
      }
      setSoldOut(
        parseInt(currentSeat[0].Capacity) <= parseInt(currentSeat[0].Count)
      );
    }
  }, [tickets]);

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // form input check
  useEffect(() => {
    if (
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  const createLocation = (e) => {
    if (e) e.preventDefault();

    setLoad(true);

    const params = {
      Billing: {
        First_Name: fName,
        Last_Name: lName,
        Zip: zip,
        Country__: country,
        Address: address,
        City: city,
        Province: province,
        Contact_Phone: phone,
      },
      seat: currentSeat[0].Seat_Name,
      quantity: quantity,
    };

    rest("Catalog/Cart/@:reset", "POST");

    rest(
      `Planner/Event/${event.data.Planner_Event__}:reserveOrder`,
      "POST",
      params
    )
      .then((data) => {
        setLoad(false);
        history.push("/order/" + data.data.Order.Order__);
      })
      .catch((data) => {
        setLoad(false);

        setTimeout(() => {
          if (data.message !== void 0) {
            error(data.message, {
              position: "top-center",
              autoClose: 3000,
            });
          } else {
            if (data.error === "Invalid subscription") {
              error(t("error_invalid_subsc"), {
                position: "top-center",
                autoClose: 3000,
              });
            } else {
              error(data.error, {
                position: "top-center",
                autoClose: 3000,
              });
            }
          }
        }, 100);
      });
  };

  useEffect(() => {
    if (event !== null) {
      rest(
        `Planner/Event/${event.data.Planner_Event__}/Seat/Reserve`,
        "GET",
        {}
      )
        .then((data) => {
          setTickets(data.data);
        })
        .catch((data) => {
          error(data.message, {
            position: "top-center",
            autoClose: 3000,
          });
        });
      let filterSeat = "";
      if (hash.seat !== void 0) {
        filterSeat = _.filter(event.data.Planner_Event.Seats, function (o) {
          return o.Planner_Event_Seat__ === hash.seat;
        });
      } else {
        filterSeat = [event.data.Planner_Event.Seats[0]];
      }
      setCurrentSeat(filterSeat);
    }
  }, [event]);

  const setQuantityVal = (val) => {
    if (maxAmount < val) {
      setQuantity(maxAmount);
    } else {
      setQuantity(val);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t("common_checkout")} - ${t("ccommon_meta_title")}`}</title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}${location.pathname}`}
        />
        <meta
          property="og:title"
          content={`${t("common_checkout")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}${
            location.pathname
          }`}
        />
      </Helmet>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <h3 className="c-ttl-5">{t("common_checkout")}</h3>
            {event === null && user === null && <Loading />}
            {event !== null && user !== null && (
              <>
                <ReserveList currentSeat={currentSeat} event={event} />
                {currentSeat[0]?.Type !== "stream" && !soldOut && !maxOut && (
                  <InputForm
                    type="number"
                    value={quantity}
                    name="quantity"
                    label={t("cart_label_quantity")}
                    handleChange={setQuantityVal}
                    addClass="mgt-pc--30 mgt-sp--40"
                    min={1}
                    max={maxAmount}
                    required={false}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            {soldOut && (
              <p className={styles["reserve-attention-text"]}>
                {t("common_sold_out")}
              </p>
            )}
            {maxOut && (
              <p className={styles["reserve-attention-text"]}>
                {t("common_buy_limit")}
              </p>
            )}
            {!soldOut && !maxOut && (
              <>
                <BillingInputs
                  fName={fName}
                  setFName={setFName}
                  lName={lName}
                  setLName={setLName}
                  zip={zip}
                  setZip={setZip}
                  province={province}
                  setProvince={setProvince}
                  city={city}
                  setCity={setCity}
                  address={address}
                  setAddress={setAddress}
                  phone={phone}
                  setPhone={setPhone}
                  country={country}
                  setCountry={setCountry}
                  geoVal={geoVal}
                  Countrys={Countrys}
                  formEnabled={formEnabled}
                  setFormEnabled={setFormEnabled}
                  load={load}
                />
                <div className={styles["reserve-btns"]}>
                  <Button
                    disabled={!formEnabled || load}
                    onClick={() => {
                      createLocation();
                    }}
                  >
                    {t("common_checkout_order")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {load && <LoadingOverLay />}
      <Toast duration={5000} />
    </>
  );
};

export default Reserve;
