import React, { useEffect, useState } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Typography, Button } from "@material-ui/core";

// component
import SelectInput from "components/Forms/SelectInput";
import StripeWrapper from "components/Payment/StripeWrapper";

// styles
import styles from "../../MyPage.module.scss";

// const
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    maxHeight: "100%",
    width: "750px",
    overflowY: "auto",
    "@media all and (max-width: 750px)": {
      width: "100%",
    },
  },
}));

const BillingCreate = ({
  addModal = false,
  setAddModal,
  billingRefresh,
  setLoad,
  load,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [userLocations] = useRest(
    "User/@/Location",
    {
      page_no: 1,
      results_per_page: 100,
      sort: { Created: "DESC" },
    },
    true
  );

  const [locationArray, setLocationArray] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(() => {
    if (userLocations === null) return;
    const locationArray = _.map(userLocations.data, (val) => {
      return {
        label: `${val.Display[0]}${val.Display[1]}${val.Display[2]}${
          val.Display[3] === void 0 ? "" : val.Display[3]
        }
      ${val.Display[4] === void 0 ? "" : val.Display[4]}`,
        val: val,
      };
    });

    setLocationArray(locationArray);
  }, [userLocations]);

  return (
    <>
      <Modal
        className={`${classes.modal} ${styles["billing-modal"]}`}
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <div className={classes.paper}>
          {locationArray !== null && locationArray.length === 0 && (
            <>
              <p style={{ marginTop: "30px" }}>
                {t("mypage_billing_location_nothing")}
              </p>
              <div>
                <Button
                  component={Link}
                  className={`c-btn-3__item mgb-pc--20 mgb-sp--20`}
                  style={{ textAlign: "center" }}
                  to="/mypage/location"
                >
                  {t("maypage_tab_location")}
                </Button>
              </div>
            </>
          )}
          {locationArray !== null && locationArray.length !== 0 && (
            <>
              <Typography
                className={styles["member-ship-modal-title"]}
                align="left"
                variant="h6"
                component="h2"
                style={{
                  marginBottom: "20px",
                }}
              >
                {t("mypage_billing_add")}
              </Typography>
              <p>{t("mypage_label_locations_text")}</p>
              <SelectInput
                name="locations"
                label={t("mypage_label_locations")}
                value={currentLocation}
                array={locationArray}
                handleChange={setCurrentLocation}
                disabled={load}
                required={true}
                addClass="mgt-pc--30 mgt-sp--40"
              />
            </>
          )}

          {currentLocation !== null && (
            <div>
              <StripeWrapper
                billing={currentLocation}
                type="add"
                billingRefresh={billingRefresh}
                load={load}
                setLoad={setLoad}
                setAddModal={setAddModal}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default BillingCreate;
