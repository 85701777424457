import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Material Ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "assets/jss/Theme";
import { StylesProvider } from "@material-ui/core/styles";

// layout
import Layout from "components/Layouts/Layout";

// page
import Index from "views/Index/Index";
import Info from "views/Info/Info";
import InfoDetail from "views/Info/Detail";
import Contact from "views/Contact/Contact";
// import Login from "views/Login/Login";
import MyPage from "views/MyPage/MyPage";
// import FanclubPlan from "views/Fanclub/Plan";
// import Subscribe from "views/Subscribe/Subscribe";
// import Fanclub from "views/Fanclub/Fanclub";
import FanclubDetail from "views/Fanclub/Detail";
import Page from "views/Pages/Detail";
// import OperatingCompany from "views/OperatingCompany/OperatingCompany";
import Order from "views/Order/Order";
import Transfer from "views/Transfer/Transfer";

// event
import Event from "views/Event/Event";
import EventDetail from "views/Event/Detail";
import Reserve from "views/Reserve/Reserve";

// cart
import Cart from "views/Cart/Cart";

// checkout
import CheckOut from "views/Checkout/Checkout";

// password
import ForgottenPassword from "views/ForgottenPassword/ForgottenPassword";
import PasswordReset from "views/PasswordReset/PasswordReset";

// Withdrawal
import Withdrawal from "views/Withdrawal/Withdrawal";

// util
import LoginRequired from "utils/LoginRequired";
// import MemberRequired from "utils/MemberRequired";

const Pages = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Index />
            </Route>
            <Route exact path="/info">
              <Info />
            </Route>
            <Route exact path="/info/page-:pageNum">
              <Info />
            </Route>
            <Route exact path="/info/:slug">
              <InfoDetail />
            </Route>
            <Route exact path="/event">
              <Event />
            </Route>
            <Route exact path="/event/page-:pageNum">
              <Event />
            </Route>
            <Route exact path="/event/:slug">
              <EventDetail />
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/checkout">
              <LoginRequired>
                <CheckOut />
              </LoginRequired>
            </Route>
            <Route exact path="/reserve/:slug">
              <LoginRequired>
                <Reserve />
              </LoginRequired>
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/order/:slug">
              <Order />
            </Route>
            <Route exact path="/pages/:slug">
              <Page />
            </Route>
            {/* <Route exact path="/legal-information">
              <OperatingCompany />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route> */}
            <Route exact path="/mypage">
              <LoginRequired>
                <MyPage type="top" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/history">
              <LoginRequired>
                <MyPage type="history" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/history/page-:pageNum">
              <LoginRequired>
                <MyPage type="history" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/user">
              <LoginRequired>
                <MyPage type="user" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/location">
              <LoginRequired>
                <MyPage type="location" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/location/page-:pageNum">
              <LoginRequired>
                <MyPage type="location" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/profile">
              <LoginRequired>
                <MyPage type="profile" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/password">
              <LoginRequired>
                <MyPage type="password" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/billing">
              <LoginRequired>
                <MyPage type="billing" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/membership">
              <LoginRequired>
                <MyPage type="membership" />
              </LoginRequired>
            </Route>
            {/* <Route exact path="/subscribe">
              <LoginRequired>
                <FanclubPlan />
              </LoginRequired>
            </Route>
            <Route exact path="/subscribe/:slug">
              <LoginRequired>
                <Subscribe />
              </LoginRequired>
            </Route> */}
            {/* <Route exact path="/fanclub">
              <LoginRequired>
                <Fanclub />
              </LoginRequired>
            </Route>
            <Route exact path="/fanclub/page-:pageNum">
              <LoginRequired>
                <Fanclub />
              </LoginRequired>
            </Route>
            <Route exact path="/fanclub/:slug">
              <LoginRequired>
                <FanclubDetail />
              </LoginRequired>
            </Route> */}
            <Route exact path="/articles/:slug">
              <FanclubDetail />
            </Route>
            <Route exact path="/transfer">
              <Transfer />
            </Route>
            <Route exact path="/forget-password">
              <ForgottenPassword />
            </Route>
            <Route exact path="/recover-password/:login">
              <PasswordReset />
            </Route>
            <Route exact path="/withdrawal">
              <Withdrawal />
            </Route>
            <Route exact path="/operating-company">
              <Redirect to="/legal-information" />
            </Route>
            <Redirect from="/events/:slug" to="/event/:slug" />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Layout>
      </StylesProvider>
    </ThemeProvider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
