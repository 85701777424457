import React from "react";

// component

// style
import styles from "./NotFound.module.scss";

// img

const NotFound = () => {
  return (
    <div className="c-block">
      <div className={styles["notFound-container"]}>
        <h1 className={styles["notFound-title"]}>
          <span>404</span>PAGE NOT FOUND
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
