import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import Loading from "components/Loading/Loading";
import DisplayEvent from "components/Event/DisplayEvent";
import NotFound from "components/NotFound/NotFound";
import FadeUp from "components/FadeUp/FadeUp";
import Streaming from "views/Event/Streaming";

// style

// constant
import { IMG_VARIATION_INFOMATION_DETAIL } from "const/const";

const useQuery = () => new URLSearchParams(useLocation().search);

const EventDetail = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const query = useQuery();
  const args = {
    slug: decodeURIComponent(slug),
    image_variation: IMG_VARIATION_INFOMATION_DETAIL,
  };

  if (query.get("_preview")) {
    args._preview = query.get("_preview");
  }

  const [article] = useRest("Planner/@planner/Schedule:loadBySlug", args, true);

  if (slug === "plns-wu34ts-4oo5-cuxi-kemk-m2yjbgjy") {
    return <Redirect to="/event/1-TVアニメ『風が強く吹いている』コンサート" />;
  }

  if (article === false) {
    return <NotFound />;
  } else if (article === null) {
    return <Loading />;
  } else {
    return (
      <FadeUp>
        <div className="c-block c-block--info">
          {article.data.planner_schedule.Planner_Event.Seat_Types.find(
            (type) => {
              return type === "stream";
            }
          ) && (
            <Streaming
              eventId={article.data.planner_schedule.Planner_Event__}
              label={article.data.content_cms_entry_data.Slug}
            />
          )}
          <div className="c-block__container">
            <section className="c-section-7">
              <h3 className="c-ttl-5">{t("common_event")}</h3>

              <div className="c-section-7__content">
                <article className="c-article">
                  {article === null && <Loading />}
                  {article !== null && <DisplayEvent article={article} />}
                </article>
              </div>
            </section>
          </div>
        </div>
      </FadeUp>
    );
  }
};

export default EventDetail;
