import React from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import SlideUp from "components/SlideUp/SlideUp";
import FadeUp from "components/FadeUp/FadeUp";

// style
// import styles from "../Index.module.scss";

// img
import ProfileImg from "assets/img/img-profile.png";
import ProfileSpImg from "assets/img/img-profile-sp.png";

const Profile = () => {
  const { t } = useTranslation();
  const defaultLng = getLocale();

  return (
    <section className="c-section c-section--none">
      <div className="c-section__container">
        <div className="c-box">
          <h2 id="profile" className="c-ttl-2">
            <span className="c-ttl-2__main">
              <SlideUp>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("common_profile_animation"),
                  }}
                />
              </SlideUp>
            </span>
            {defaultLng === "ja-JP" && (
              <FadeUp>
                <span className="c-ttl-2__sub">{t("mainNav_profile")}</span>
              </FadeUp>
            )}
          </h2>

          <div className="c-box__inner">
            <div className="c-box__contents">
              <FadeUp>
                <div className="c-box__img">
                  <img src={ProfileImg} alt="" className="sp-hide" />
                  <img src={ProfileSpImg} alt="" className="pc-hide" />
                </div>
              </FadeUp>
              <FadeUp>
                <div className="c-box__desc">
                  <p className="c-box__name-ja">{t("profile_name")}</p>
                  {defaultLng === "ja-JP" && (
                    <p className="c-box__name-en">{t("profile_name_sub")}</p>
                  )}
                  <p
                    className="c-box__txt"
                    dangerouslySetInnerHTML={{ __html: t("profile_text") }}
                  />
                </div>
              </FadeUp>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
