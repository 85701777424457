import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

export default ({ children }) => {
  const inputElement = useRef(null);
  const [slideUp, setSlideUp] = useState(false);

  const scrollCheck = () => {
    const scroll =
      inputElement.current.getBoundingClientRect().top - window?.innerHeight;

    if (scroll <= 0) {
      setSlideUp(true);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (inputElement.current !== null) {
        window.addEventListener("scroll", scrollCheck);
        return () => {
          window.removeEventListener("scroll", scrollCheck);
        };
      }
    }
  }, [inputElement]);

  useEffect(() => {
    setTimeout(() => {
      scrollCheck();
    }, 100);
  }, []);

  return (
    <div
      ref={inputElement}
      className={clsx(`js-fadeup`, {
        ["is-fadeup"]: slideUp,
      })}
    >
      {children}
    </div>
  );
};
