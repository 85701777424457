import React from "react";

// material ui
import { FormControlLabel, Checkbox } from "@material-ui/core";

// style
import styles from "./Form.module.scss";

const CheckBoxForm = ({
  label = "default",
  handleChange = null,
  value = false,
  disabled = false,
  name = "defaule",
}) => {
  return (
    <FormControlLabel
      className={styles["form-checkbox"]}
      control={
        <Checkbox
          checked={value}
          onChange={(e) => {
            handleChange(e);
          }}
          name={name}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};

export default CheckBoxForm;
