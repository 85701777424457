import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// component
import Button from "components/Btns/Btn";

// utils

// styles
import styles from "../Event.module.scss";

// img

// const

const Tickets = ({ eventId, eventStatus, eventSchedule }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {eventId !== null && eventStatus === "reserve_open" && (
        <div className={styles["event-btns-container"]}>
          {eventSchedule.Planner_Event.Seats.map((item, key) => {
            return (
              <div className={styles["event-btns-list"]} key={key}>
                <dl className={styles["event-btns-list-group"]}>
                  <dt className={styles["event-btns-list-title"]}>
                    {item.Seat_Name_Display}
                  </dt>
                  <dd className={styles["event-btns-list-desc"]}>
                    {item.Description}
                  </dd>
                  {item.Price.value_disp === "0" && (
                    <dd className={styles["event-btns-list-price"]}>
                      <span className={styles["event-btns-list-price-strong"]}>
                        {t("common_free_price")}
                      </span>
                    </dd>
                  )}
                  {item.Price.value_disp !== "0" && (
                    <dd className={styles["event-btns-list-price"]}>
                      {t("common_pricet")}：
                      <span className={styles["event-btns-list-price-strong"]}>
                        {item.Price.tax.display_short}
                      </span>{" "}
                      {t("common_tax_include")}
                    </dd>
                  )}
                </dl>
                <div className={styles["event-btns-list-title-btn"]}>
                  <Button
                    onClick={() => {
                      history.push(
                        `/reserve/${eventSchedule.Planner_Schedule__}?seat=${item.Planner_Event_Seat__}`
                      );
                    }}
                  >
                    {t("common_buy")}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Tickets;
