import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

// component
import Button from "components/Btns/Btn";
import CheckBox from "components/Forms/CheckBox";

// styles
import styles from "./Payment.module.scss";

// const

const StripeForm = ({
  submitForm,
  load,
  setEdit,
  type = "submit",
  setStripe,
  formEnabled,
  ccRemember,
  setCcRemember,
  intentStatus,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const stripeElements = useElements();
  const [strtipeEnabled, setStripeEnabeld] = useState();
  const [paymentType, setPaymentType] = useState("card");
  const [pageType, setPageType] = useState(null);

  useEffect(() => {
    setStripe(stripe);
  }, [setStripe, stripe]);

  useEffect(() => {
    const type = new URLSearchParams(window.location.search).get("type");
    if (type === "history") {
      setPageType(false);
    } else {
      setPageType(true);
    }
  }, []);

  const submitHandler = () => {
    setStripeEnabeld(false);
    submitForm(stripe, stripeElements);
  };

  const settingPropsObject = () => {
    let commonObj = {
      onClick: submitHandler,
      iconPosition: "noting",
      disabled: load || !strtipeEnabled,
    };

    let styleObj = {
      className: styles["payment-btns"],
    };

    let btnName = t("common_credit_purchase");

    switch (type) {
      case "withBilling":
        commonObj["disabled"] = load || !formEnabled || !strtipeEnabled;
        btnName = t("common_save");
        break;
      case "update":
        styleObj["className"] = styles["payment-btns-group"];
        btnName = t("mypage_payment_update");
        break;
      case "add":
        styleObj["className"] = styles["payment-btns-group"];
        btnName = t("mypage_payment_add");
        break;
      default:
        break;
    }

    return {
      styles: styleObj,
      button: commonObj,
      btnName: btnName,
    };
  };

  const paymentElementOptions = {
    layout: "tabs",
    theme: "stripe",
  };

  return (
    <>
      <PaymentElement
        options={paymentElementOptions}
        onChange={(e) => {
          setPaymentType(e.value.type);
          setStripeEnabeld(e.complete);
        }}
        disabled={true}
      />
      {type === "order" &&
        paymentType === "card" &&
        pageType !== null &&
        intentStatus === "requires_payment_method" && (
          <CheckBox
            value={ccRemember}
            name={"ccrember"}
            label={t("common_cc_remember")}
            handleChange={() => {
              setCcRemember(!ccRemember);
            }}
          />
        )}
      <div {...settingPropsObject().styles}>
        {type === "update" && (
          <Button
            onClick={() => {
              setEdit(false);
            }}
            iconPosition="noting"
            type="danger"
          >
            {t("mypage_payment_cancel")}
          </Button>
        )}
        <Button {...settingPropsObject().button}>
          {settingPropsObject().btnName}
        </Button>
      </div>
    </>
  );
};

export default StripeForm;
