import React from "react";
import { useTranslation } from "react-i18next";

// component

// utils

// style
import styles from "./Goods.module.scss";

// img

const TopGoodsList = ({ goods }) => {
  const { t } = useTranslation();

  return (
    <>
      {goods !== null && goods !== false && (
        <>
          <p className={styles["notfound-text"]}>{t("error_not_goods")}</p>
        </>
      )}
    </>
  );
};

export default TopGoodsList;
