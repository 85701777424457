import React, { useState, useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { error } from "components/Toast/Toast";
import { getPrefix } from "@karpeleslab/klbfw";

// component
import HelmetComponents from "components/Helmet/Helmet";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";

// utile
import { validateEmail } from "utils/Validation";

// style
import styles from "./ForgottenPassword.module.scss";

// img

// const

const ForgottenPassword = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);
  const [email, setEmail] = useState("");
  const [comp, setComp] = useState(false);

  const forgotPost = (e) => {
    e.preventDefault();

    setloading(true);
    rest("User:forgot_password", "POST", { login: email })
      .then(() => {
        setloading(false);
        setComp(true);
      })
      .catch((err) => {
        setloading(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (!validateEmail(email) || !email.trim()) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [email]);

  return (
    <>
      <HelmetComponents>
        <title>
          {t("fotgot_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/forget-password`}
        />
        <meta
          property="og:title"
          content={`${t("fotgot_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/forget-password`}
        />
      </HelmetComponents>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <h2 className="c-ttl-2">
            <span className="c-ttl-2__main">{t("fotgot_title")}</span>
          </h2>
          <div className={styles["content-container"]}>
            <div className={styles["forgot-container"]}>
              {comp && (
                <>
                  <p className={styles["forgot-text"]}>
                    {t("forgot_comp_text")}
                  </p>
                  <div className={styles["forgot-btn-group"]}>
                    <Button to="/">{t("fotgot_top_link")}</Button>
                  </div>
                </>
              )}
              {!comp && (
                <>
                  <p
                    className={styles["forgot-text"]}
                    dangerouslySetInnerHTML={{ __html: t("fotgot_text") }}
                  ></p>
                  <form onSubmit={(e) => forgotPost(e)}>
                    <InputForm
                      type="email"
                      value={email}
                      name="user-email"
                      label={t("forgot_label_email")}
                      placeholder={t("input_field_placeholder", {
                        text: t("forgot_label_email"),
                      })}
                      handleChange={setEmail}
                      disabled={loading}
                    />
                    <div className={styles["forgot-btn-group"]}>
                      <Button
                        btnTypes="submit"
                        disabled={!formEnabled || loading}
                      >
                        {t("fotgot_submit")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingOverLay />}
    </>
  );
};

export default ForgottenPassword;
