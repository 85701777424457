import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

// component

// style

// img

const Input = ({
  label,
  required = true,
  name,
  addClass = null,
  type = "text",
  value,
  handleChange = null,
  confirm = false,
  disabled = false,
  min = 1,
  max = 1,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("c-input-1", {
        ["mgt-pc--30 mgt-sp--40"]: addClass !== null,
      })}
    >
      <div className="c-input-1__txt">
        <p className="c-input-1__txt-ttl">
          <span>{label}</span>
        </p>
        {required && (
          <p className="c-input-1__txt-caption">
            <span
              dangerouslySetInnerHTML={{
                __html: t("contact_input_required"),
              }}
            ></span>
          </p>
        )}
      </div>
      {confirm && value}
      {!confirm && (
        <input
          type={type}
          name={`text-${name}`}
          className="c-input-1__item"
          size="60"
          value={value}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          disabled={disabled}
          min={min}
          max={max}
        />
      )}
    </div>
  );
};

export default Input;
