import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Pagination } from "@material-ui/lab";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

// style
import styles from "./Pagination.module.scss";

const Paging = ({
  count = 0,
  defaultPage = 1,
  setCurrentPage = null,
  path = "",
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { pageNum } = useParams();
  const page = pageNum === void 0 ? 1 : pageNum;

  const pageChange = (event, page) => {
    let linkPath = `/${location.pathname.split("/")[1]}/page-${page}${
      location.search
    }`;

    if (path !== "") {
      linkPath = `/${path}/page-${page}${location.search}`;
    }

    setCurrentPage(page);
    history.push(linkPath);
  };

  return (
    <div className={styles["pagination-container"]}>
      <div className={styles["pagination"]}>
        {count !== 0 && (
          <>
            <button
              onClick={() => {
                pageChange(null, parseInt(defaultPage) - 1);
                window.scrollTo(0, 0);
              }}
              className={styles["pagination-btn"]}
              disabled={parseInt(defaultPage) === 1}
            >
              <ArrowBackIos />
              {t("paginate_prev")}
            </button>
            <Pagination
              className={styles["pagination-list"]}
              defaultPage={parseInt(page)}
              page={parseInt(defaultPage)}
              onChange={(event, page) => {
                pageChange(event, page);
                window.scrollTo(0, 0);
              }}
              count={count}
              hideNextButton={true}
              hidePrevButton={true}
            />
            <button
              onClick={() => {
                pageChange(null, parseInt(defaultPage) + 1);
                window.scrollTo(0, 0);
              }}
              className={styles["pagination-btn"]}
              disabled={parseInt(defaultPage) === count}
            >
              {t("paginate_next")}
              <ArrowForwardIos />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Paging;
