import React, { useState } from "react";
import _ from "lodash";
import clsx from "clsx";

// component
import { buildDate } from "utils/BuildDate";

// style

// img
import NoImg from "assets/img/img-no-img.jpg";

const ThumbnailItem = ({ val, tag = "WORKS" }) => {
  const [itemHover, setItemHover] = useState(false);
  const IMG_VARIATION = "format=jpeg&strip&scale_crop=300x300";

  const tagDisplay = (tags) => {
    const tagArray = _.map(tags, "Full_Name");
    const currentTag = _.filter(tagArray, (value) => {
      if (value !== tag) {
        return value;
      }
    });

    return currentTag[0];
  };

  if (val.Music_Label_Album.Links.length !== 0) {
    return (
      <div
        className={clsx(
          "c-list-circle__item js-SortTarget js-hover-taps active",
          {
            ["is-hover"]: itemHover,
          }
        )}
        onMouseEnter={() => {
          setItemHover(true);
        }}
        onMouseLeave={() => {
          setItemHover(false);
        }}
      >
        <a
          href={val.Music_Label_Album.Links[0].Link}
          className="c-list-circle__link"
        >
          <p className="c-list-circle__img">
            <img
              src={
                val?.Drive_Item__ !== null
                  ? val.Music_Label_Album.Drive_Item.Media_Image.Variation[
                      IMG_VARIATION
                    ]
                  : NoImg
              }
              alt=""
              className="js-object-fit"
            />
          </p>
          <div className="c-list-circle__content">
            <div className="c-list-circle__content-ttl">
              <p>{val.Music_Label_Album.Name}</p>
            </div>
            <div className="c-list-circle__content-txt">
              <p>{buildDate(parseInt(val.Release_Date.unixms), "YYYY")}</p>
            </div>

            <p className="c-list-circle__content-label">
              <span className="c-list-circle__content-label-item">
                {tagDisplay(val.Tags)}
              </span>
            </p>

            <p className="c-list-circle__content-btn">
              <span className="c-list-circle__content-btn-item">CHECK</span>
            </p>
          </div>
        </a>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "c-list-circle__item js-SortTarget js-hover-taps active",
        {
          ["is-hover"]: itemHover,
        }
      )}
      onMouseEnter={() => {
        setItemHover(true);
      }}
      onMouseLeave={() => {
        setItemHover(false);
      }}
    >
      <div className="c-list-circle__link">
        <p className="c-list-circle__img">
          <img
            src={
              val.Drive_Item__ !== null
                ? val.Music_Label_Album.Drive_Item.Media_Image.Variation[
                    IMG_VARIATION
                  ]
                : NoImg
            }
            alt=""
            className="js-object-fit"
          />
        </p>
        <div className="c-list-circle__content">
          <div className="c-list-circle__content-ttl js-language-jp">
            <p>{val.Music_Label_Album.Name}</p>
          </div>
          <div className="c-list-circle__content-txt js-language-jp">
            <p>{buildDate(parseInt(val.Release_Date.unixms), "YYYY")}</p>
          </div>

          <p className="c-list-circle__content-label">
            <span className="c-list-circle__content-label-item">
              {tagDisplay(val.Tags)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThumbnailItem;
