import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";
import NoSsr from "@material-ui/core/NoSsr";

// component
import Loading from "components/Loading/Loading";
import Like from "./section/Like";

// utils
import { buildDate } from "utils/BuildDate";

// style
import styles from "./Cms.module.scss";

// img
import LinkIcon from "assets/img/icon-link.svg";
import LinkIconHover from "assets/img/icon-link-hover.svg";

const ListDisplay = ({ news, path = "info", tag = "NEWS", type = "news" }) => {
  const { t } = useTranslation();

  const tagDisplay = (tags) => {
    const tagArray = _.map(tags, "Full_Name");
    const currentTag = _.filter(tagArray, (value) => {
      if (value !== tag) {
        return value;
      }
    });
    return currentTag[0];
  };

  return (
    <>
      {news === null && <Loading />}
      {news !== null && news !== false && (
        <>
          {news.data.data.length === 0 && (
            <div className="c-list">
              <div
                className={`${styles["cms-article-notfound"]} ${styles["cms-article-notfound-list"]}`}
              >
                <p className={styles["cms-article-notfound-text"]}>
                  {t("error_not_found")}
                </p>
              </div>
            </div>
          )}
          {news.data.data.length !== 0 && (
            <ul className="c-list">
              {news.data.data.map((val, index) => {
                return (
                  <li key={index} className="c-list__item">
                    <Link to={`/${path}/${val.Slug}`} className="c-list__link">
                      <div
                        className="c-list__inner"
                        style={{
                          position: "relative",
                        }}
                      >
                        <span className="c-list__date">
                          {buildDate(
                            parseInt(val.Published.unixms),
                            "MMM DD, YYYY"
                          )}
                        </span>
                        {type === "news" && (
                          <span className="c-list__cat">
                            {tagDisplay(val.Tags)}
                          </span>
                        )}
                        <span className="c-list__ttl">
                          <span>{val.Title}</span>
                          {type === "fanclub" && (
                            <NoSsr>
                              <Like
                                className={styles["like-list"]}
                                cmsEntry={val.Content_Cms_Entry__}
                                likes={val.Likes}
                              />
                            </NoSsr>
                          )}
                        </span>
                      </div>
                      <span
                        className="c-list__link-icon"
                        style={{
                          backgroundImage: `url(${LinkIcon})`,
                        }}
                      ></span>
                      <span
                        className="c-list__link-icon"
                        style={{
                          backgroundImage: `url(${LinkIcon})`,
                        }}
                      ></span>
                      <span
                        className="c-list__link-icon c-list__link-icon-hover"
                        style={{
                          backgroundImage: `url(${LinkIconHover})`,
                        }}
                      ></span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default ListDisplay;
