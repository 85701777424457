import React from "react";
import { useTranslation } from "react-i18next";
import { getPrefix } from "@karpeleslab/klbfw";

// component
import HelmetComponents from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";

// utils
import { buildDate } from "utils/BuildDate";

// styles
import styles from "./Cms.module.scss";

const DisplayPage = ({ article, param }) => {
  const { t } = useTranslation();
  const articleDetail = article?.data?.content_cms_entry_data;

  return (
    <>
      <div className="c-block">
        {article === null && (
          <div className={styles["cms-load"]}>
            <Loading />
          </div>
        )}
        {article !== null && article !== false && (
          <>
            <HelmetComponents>
              <title>
                {article.data.content_cms_entry_data.Title} -{" "}
                {t("ccommon_meta_description")}
              </title>
              <link
                rel="canonical"
                href={`https://www.yukihayashi.net${getPrefix()}/pages/${param}`}
              />
              <meta
                property="og:title"
                content={`${article.data.content_cms_entry_data.Title} - ${t(
                  "ccommon_meta_title"
                )}`}
              />
              <meta
                property="og:url"
                content={`https://www.yukihayashi.net${getPrefix()}/pages/${param}`}
              />
            </HelmetComponents>
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                <div className={styles["cms-head"]}>
                  <h1 className={`c-ttl-5 ${styles["cms-title"]}`}>
                    {article.data.content_cms_entry_data.Title}
                  </h1>
                  <p className={styles["cms-date"]}>
                    {buildDate(
                      parseInt(
                        article.data.content_cms_entry_data.Published.unixms
                      ),
                      "YYYY.MM.DD"
                    )}
                  </p>
                </div>
                <div
                  className={styles["cms-contents-page"]}
                  dangerouslySetInnerHTML={{
                    __html: articleDetail.Contents,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DisplayPage;
