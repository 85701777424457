import React from "react";

// material ui
import CircularProgress from "@material-ui/core/CircularProgress";

// style
import styles from "./Loading.module.scss";

const LoadingElements = ({ className }) => {
  return (
    <div className={className}>
      <CircularProgress className={styles["loading"]} color="secondary" />
    </div>
  );
};

export default LoadingElements;
