import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { getPrefix } from "@karpeleslab/klbfw";

// component
import Loading from "components/Loading/Loading";
import EventList from "components/List/EventList";
import HelmetComponents from "components/Helmet/Helmet";
import Pagination from "components/Pagination/Pagination";
import FadeUp from "components/FadeUp/FadeUp";

// style

// constant
import { LIVE_PARAMS } from "const/const";

const Event = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pageNum } = useParams();

  //pages
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [event] = useRest(
    "Planner/@planner/Schedule",
    {
      ...LIVE_PARAMS,
      ...{ page_no: currentPage },
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <>
      <HelmetComponents>
        <title>
          {t("common_event")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/event`}
        />
        <meta
          property="og:title"
          content={`${t("common_event")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/event`}
        />
      </HelmetComponents>
      <FadeUp>
        <div className="c-block">
          <div className="c-block__container">
            <section className="c-section-6">
              <h3 className="c-ttl-5">{t("common_event")}</h3>

              <div className="c-section-6__content">
                {event === null && <Loading />}
                {event !== null && <EventList vals={event?.data} />}
                {event !== null && (
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    defaultPage={currentPage}
                    count={event?.paging?.page_max}
                  />
                )}
              </div>
            </section>
          </div>
        </div>
      </FadeUp>
    </>
  );
};

export default Event;
