import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import Loading from "components/Loading/Loading";
import DetailDisplay from "components/Cms/DetailDisplay";
import FadeUp from "components/FadeUp/FadeUp";

// icon

// utils

// style

// img

const useQuery = () => new URLSearchParams(useLocation().search);

const FanclubDetail = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const query = useQuery();
  const args = {
    slug: decodeURIComponent(slug),
  };

  if (query.get("_preview")) {
    args._preview = query.get("_preview");
  }

  const [article] = useRest(`Membership/@fanclub:loadArticleSlug`, args, true);
  return (
    <FadeUp>
      <div className="c-block c-block--info">
        <div className="c-block__container">
          <section className="c-section-7">
            <h3 className="c-ttl-5">{t("common_fanclub")}</h3>
            <div className="c-section-7__content">
              <article className="c-article">
                {article === null && <Loading />}
                {article !== null && (
                  <DetailDisplay article={article} type="fanclub" />
                )}
              </article>
            </div>
          </section>
        </div>
      </div>
    </FadeUp>
  );
};

export default FanclubDetail;
