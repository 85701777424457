import React from "react";
import i18next from "i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ duration = 2000, position = "top-center" }) => {
  return (
    <>
      <ToastContainer
        position={position}
        autoClose={duration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};
export default Toast;

const translationHandler = (msg, translate) => {
  if (translate) {
    return i18next.t(msg);
  } else {
    return msg;
  }
};

const toastTypeHandller = (
  msg,
  translate = true,
  type = "default",
  setting = null
) => {
  switch (type) {
    case "error":
      toast.error(translationHandler(msg, translate), { ...setting });
      break;
    case "success":
      toast.success(translationHandler(msg, translate), { ...setting });
      break;
    case "warning":
      toast.warning(translationHandler(msg, translate), { ...setting });
      break;
    case "info":
      toast.info(translationHandler(msg, translate), { ...setting });
      break;
    case "dark":
      toast.dark(translationHandler(msg, translate), { ...setting });
      break;
    default:
      toast(translationHandler(msg, translate), { ...setting });
      break;
  }
};

export const error = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "error", setting);
};

export const success = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "success", setting);
};

export const warning = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "warning", setting);
};

export const info = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "info", setting);
};

export const toastDark = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "dark", setting);
};

export const toastDefault = (msg, translate = true, setting) => {
  toastTypeHandller(msg, translate, "default", setting);
};
