import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";
import clsx from "clsx";

// component
import Loading from "components/Loading/Loading";
import InputForm from "components/Forms/InputForm";
import CheckBox from "components/Forms/CheckBox";
import Button from "components/Btns/Btn";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import HelmetComponents from "components/Helmet/Helmet";
import Avatar from "components/Avatar/Avatar";
import { error } from "components/Toast/Toast";

// utils

// styles
import styles from "./Withdrawal.module.scss";

const Withdrawal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [session, setSession] = useState(null);
  const [flow, updateFlow] = useState(null);
  const [user] = useRest("User:get");
  const [form, setForm] = useState({});
  const [blob, setBlob] = useState(null);
  const [loading, setLoading] = useState(false);

  const postHandller = (obj) => {
    rest("User:flow", "POST", obj).then(handleResponse).catch(handleError);
  };

  const submitTrigger = (action, event = null) => {
    if (event !== null) {
      event.preventDefault();
    }

    setLoading(true);

    switch (action.type) {
      case "submit":
        postHandller({ ...form, session: session });
        return;
      case "post":
        postHandller({ action: "delete_account" });
        return;
      case "reset":
        postHandller({ action: "delete_account" });
        return;
      default:
        return null;
    }
  };

  const handleResponse = (response) => {
    if (response.data.complete) {
      history.push("/");
      return;
    }

    if (response.data.session) {
      setSession(response.data.session);
    }

    updateFlow(response);
    setLoading(false);
  };

  const handleError = (response) => {
    setLoading(false);
    error(response.message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const formChange = (event) => {
    const array = {};
    array[event.target.getAttribute("name")] =
      event.target.type !== "checkbox"
        ? event.target.value
        : event.target.checked;

    setForm({
      ...form,
      ...array,
    });
  };

  const loginOAuth2 = ({ button, info }) => {
    return (
      <button
        variant="contained"
        style={{
          backgroundImage: `url(${button.logo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "70% 70%",
          borderRadius: 50,
          backgroundColor: button["background-color"],
        }}
        onClick={() =>
          submitTrigger({ type: "post", oauth2: info.OAuth2_Consumer__ })
        }
      >
        &nbsp;
      </button>
    );
  };

  useEffect(() => {
    submitTrigger({ type: "post", action: "delete_account" });
    // eslint-disable-next-line
	}, []);

  const buildItem = (field) => {
    const { type } = field;
    let labelTxt = field.label;

    switch (type) {
      case "label":
        if (field.style === "error") {
          if (field.link) {
            labelTxt = (
              <a
                href={field.link}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                {labelTxt}
              </a>
            );
          }
          return (
            <label
              className={clsx(styles["login-form-label"], {
                [styles["login-form-label-error"]]: field.style === "error",
              })}
            >
              {labelTxt}
            </label>
          );
        }
        if (field.link) {
          labelTxt = (
            <a href={field.link} target="_blank" rel="noopener noreferrer">
              {labelTxt}
            </a>
          );
        }
        return <label className={styles["login-form-label"]}>{labelTxt}</label>;
      case "text":
        return (
          <InputForm
            type="text"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "email":
        return (
          <InputForm
            type="email"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "password":
        return (
          <InputForm
            type="password"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "checkbox":
        if (field.link)
          labelTxt = (
            <a href={field.link} target="_blank" rel="noopener noreferrer">
              {labelTxt}
            </a>
          );
        return (
          <CheckBox
            value={form[field.name]}
            name={field.name}
            label={labelTxt}
            handleChange={formChange}
            disabled={loading}
          />
        );
      case "oauth2":
        return (
          <div className={styles["form-oauth"]}>
            {loginOAuth2({ ...field })}
          </div>
        );
      case "image":
        return <Avatar field={field} blob={blob} setBlob={setBlob} />;
      default:
        return null;
    }
  };

  const buildList = () => {
    return flow.data.fields.map((field, idx) => {
      return (
        <div className={styles["item"]} key={idx}>
          {buildItem(field)}
        </div>
      );
    });
  };

  const buildBackBtn = () => {
    if (!flow.data.initial) {
      return (
        <Button onClick={() => submitTrigger({ type: "reset" })}>
          {t("btn_back")}
        </Button>
      );
    } else {
      return null;
    }
  };

  if (user === null || flow === null || session === null) {
    return (
      <>
        <HelmetComponents>
          <title>
            {t("common_withdraw")} - {t("ccommon_meta_title")}
          </title>
          <link
            rel="canonical"
            href={`https://www.yukihayashi.net${getPrefix()}/withdraw`}
          />
          <meta
            property="og:title"
            content={`${t("common_withdraw")} - ${t("ccommon_meta_title")}`}
          />
          <meta
            property="og:url"
            content={`https://www.yukihayashi.net${getPrefix()}/withdraw`}
          />
        </HelmetComponents>
        <Loading />
      </>
    );
  }

  return (
    <>
      <HelmetComponents>
        <title>
          {t("common_withdraw")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/withdraw`}
        />
        <meta
          property="og:title"
          content={`${t("common_withdraw")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/withdraw`}
        />
      </HelmetComponents>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <h3 className="c-ttl-5">{t("common_withdraw")}</h3>
          <div className={`${styles["content-container"]}`}>
            <div className={styles["login-container"]}>
              <form
                className={styles["form"]}
                onSubmit={(e) => submitTrigger({ type: "submit" }, e)}
              >
                {buildList()}
                <div
                  id="confirm1"
                  className="c-btn-3 mgt-pc--70 mgt-sp--70 js-language-jp"
                >
                  {buildBackBtn()}
                  <Link
                    to="/mypage/membership"
                    className={`c-btn-3__item`}
                    style={{
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    {t("mypage_modal_btn_close")}
                  </Link>
                  <Button
                    className={styles["withdraw-btn-cancel"]}
                    btnTypes="submit"
                  >
                    {t("btn_next")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingOverLay />}
    </>
  );
};

export default Withdrawal;
