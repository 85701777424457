import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getToken, getPrefix, rest } from "@karpeleslab/klbfw";
import { PxlPlayer } from "@pxljp/pxl-player-react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useHistory } from "react-router-dom";

// component
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";

// utils

// styles
import styles from "./Event.module.scss";

// img

// const

const Streaming = ({ eventId, label }) => {
  const { t } = useTranslation();
  const [streamInfo, setStreamInfo] = useState(null);
  const [user] = useRest("User:get");
  const [loginCheck, setLoginCheck] = useState(false);
  const history = useHistory();

  const [options] = useState({
    eventId,
    clientId: "none",
    code: "none",
    domain: location.origin,
    endpoint: "none",
    getHeaderAuthorization: () => `Session ${getToken()}`,
    i18n: getPrefix().split("/")[2],
  });

  // streaming auto start
  const updateStreaming = (state) => {
    rest(`Planner/Event/${eventId}:stream`)
      .then((res) => {
        if (!state.enabled) return;
        setStreamInfo(res.data);
        if (res.data.Status === "none") {
          let t = res.data.Hls_Recheck_Delay;
          if (t < 5) t = 5;
          state.timeout = setTimeout(() => updateStreaming(state), t * 100);
        }
      })
      .catch((res) => {
        if (res.data.Status === "none") {
          if (!state.enabled) return;
          state.timeout = setTimeout(() => updateStreaming(state), 60000);
        }
      });
  };

  useEffect(() => {
    let state = { enabled: true };
    updateStreaming(state);
    return () => {
      state.enabled = false;
      setStreamInfo(null);
      clearTimeout(state.timeout);
    };
  }, [eventId]);

  useEffect(() => {
    if (user === null || user?.data === null) {
      setLoginCheck(false);
    } else {
      setLoginCheck(true);
    }
  }, [user]);

  return (
    <>
      {streamInfo === null && <Loading className={styles["streaming-load"]} />}
      {streamInfo !== null && (
        <div className={styles["event-stream-wrapper"]}>
          {streamInfo.Hls_Url !== "" && loginCheck && (
            <div className={styles["event-stream-inner-wrapper"]}>
              <div className={styles["event-stream-inner"]}>
                <PxlPlayer options={options} />
              </div>
            </div>
          )}
          {(streamInfo.Hls_Url === "" ||
            (streamInfo.Hls_Url !== "" && !loginCheck)) && (
            <div className="c-block__container">
              <div className="c-article">
                {streamInfo.Status === "finished" && (
                  <div className={styles["event-stream-text-wrapper"]}>
                    <p className={styles["event-stream-text"]}>
                      {t("common_streaming_finished_text")}
                    </p>
                  </div>
                )}
                {streamInfo.Status !== "finished" && streamInfo?.Has_Seat && (
                  <div className={styles["event-stream-text-wrapper"]}>
                    <p className={styles["event-stream-text"]}>
                      {t("common_streaming_text")}
                    </p>
                  </div>
                )}

                {!loginCheck &&
                  !(
                    streamInfo.Hls_Url === "" &&
                    streamInfo.Status === "finished"
                  ) && (
                    <div className={styles["event-stream-text-wrapper"]}>
                      <p className={styles["event-stream-text"]}>
                        {t("common_streaming_text_login")}
                      </p>
                      <Button
                        onClick={() => {
                          history.push(
                            `/login?return_to=/event/${decodeURIComponent(
                              label
                            )}`
                          );
                        }}
                        className={styles["event-stream-btn"]}
                      >
                        {t("common_login")}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Streaming;
