import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

// component

// style

// img

const TextArea = ({
  title,
  required = true,
  name,
  addClass = null,
  value,
  handleChange = null,
  confirm = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx("c-input-2", {
        ["mgt-pc--30 mgt-sp--40"]: addClass !== null,
      })}
    >
      <div className="c-input-2__txt">
        <p className="c-input-2__txt-ttl">
          <span>{title}</span>
        </p>
        {required && (
          <p className="c-input-1__txt-caption">
            <span
              dangerouslySetInnerHTML={{
                __html: t("contact_input_required"),
              }}
            ></span>
          </p>
        )}
      </div>
      {confirm && value}
      {!confirm && (
        <textarea
          name={`text-${name}`}
          className="c-input-2__item"
          cols="50"
          rows="5"
          value={value}
          onChange={(val) => {
            handleChange(val.target.value);
          }}
        ></textarea>
      )}
    </div>
  );
};

export default TextArea;
