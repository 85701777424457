import React from "react";
import clsx from "clsx";

// material ui
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

// component
import LoadingElements from "components/Loading/LoadingElements";

// style
import styles from "./Form.module.scss";

const Provinces = ({
  handleChange = null,
  array = null,
  value = null,
  name = null,
  required = false,
  label = "default",
  disabled = false,
  addClass = null,
}) => {
  return (
    <FormControl
      className={clsx({
        ["mgt-pc--30 mgt-sp--40"]: addClass !== null,
      })}
      variant="outlined"
      required={required}
      fullWidth
    >
      <InputLabel
        className={styles["form-label"]}
        shrink={true}
        disableAnimation={true}
        htmlFor={`${name}-helper`}
        id="demo-simple-select-outlined-label"
      >
        {label}
      </InputLabel>
      {array === null && (
        <LoadingElements className={styles["form-load-elements"]} />
      )}
      {array !== null && (
        <Select
          className={styles["form-input"]}
          id={`${name}-helper`}
          value={value === null ? "" : value}
          onChange={handleChange}
          displayEmpty
          disabled={disabled}
        >
          {array.map((val, index) => {
            return (
              <MenuItem key={index} value={val.key}>
                {val.name}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};

export default Provinces;
