import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getPrefix } from "@karpeleslab/klbfw";
import clsx from "clsx";

// component
import HelmetComponents from "components/Helmet/Helmet";
import Share from "./section/Share";
import Tickets from "./section/Tickets";

// utils
import { buildDate } from "utils/BuildDate";

// styles
import styles from "./Event.module.scss";

// img

// const

const DisplayEvent = ({ article }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const articleDetail = article?.data?.content_cms_entry_data;

  return (
    <>
      {article === false && (
        <>
          <HelmetComponents>
            <title>
              {t("error_not_found")} - {t("ccommon_meta_title")}
            </title>
          </HelmetComponents>
          <div className={styles["cms-article-notfound"]}>
            <p className={styles["cms-article-notfound-text"]}>
              {t("error_not_found")}
            </p>
          </div>
        </>
      )}
      {article !== false && (
        <>
          <HelmetComponents>
            <title>
              {articleDetail.Title} - {t("ccommon_meta_title")}
            </title>
            <link
              rel="canonical"
              href={`https://www.yukihayashi.net${getPrefix()}${
                location.pathname
              }`}
            />
            <meta
              property="og:title"
              content={`${article.data.Label} - ${t("ccommon_meta_title")}`}
            />
            <meta
              property="og:url"
              content={`https://www.yukihayashi.net${getPrefix()}${
                location.pathname
              }`}
            />
          </HelmetComponents>
          <div className="c-article__head">
            <div className="c-article__info">
              <span className="c-article__date">
                {buildDate(
                  parseInt(
                    article.data.planner_schedule.Planner_Event__ === null
                      ? article.data.planner_schedule.Date.unixms
                      : article.data.planner_schedule.Planner_Event.Event_Date
                          .unixms
                  ),
                  "MMM DD, YYYY HH:mm～"
                )}
              </span>
            </div>
            <h4 className="c-article__ttl">{articleDetail.Title}</h4>
          </div>

          <div className="c-article__content">
            {articleDetail.Top_Drive_Item__ !== null && (
              <p>
                <img
                  src={articleDetail.Top_Drive_Item.Media_Image.Url}
                  alt={articleDetail.Title}
                />
              </p>
            )}
            <Tickets
              eventId={article.data.planner_schedule.Planner_Event__}
              eventStatus={article.data.planner_schedule.Planner_Event.Status}
              eventSchedule={article.data.planner_schedule}
            />
            <div
              className="wysiwyg"
              dangerouslySetInnerHTML={{
                __html: articleDetail.Contents,
              }}
            />
            <Tickets
              eventId={article.data.planner_schedule.Planner_Event__}
              eventStatus={article.data.planner_schedule.Planner_Event.Status}
              eventSchedule={article.data.planner_schedule}
            />
          </div>
        </>
      )}
      <div
        className={clsx("c-article__foot", {
          [styles["c-article__foot__center"]]: article === false,
        })}
      >
        <span
          className="c-article__btn"
          onClick={() => {
            history.goBack();
          }}
        >
          {t("common_back_to_list")}
        </span>
        {article !== false && <Share article={article} />}
      </div>
    </>
  );
};

export default DisplayEvent;
