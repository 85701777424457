import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// material ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// style
import styles from "./List.module.scss";

const OrderList = ({ order }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container>
        <Grid style={{ textAlign: "right" }} item xs={12}>
          <p style={{ marginBottom: "20px" }}>
            {t("mypage_history_label_date")}：
            {moment(parseInt(order.data.order.Created.unixms)).format(
              "YYYY-MM-DD HH:mm:ss"
            ) !== "Invalid date"
              ? moment(parseInt(order.data.order.Created.unixms)).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "-"}
          </p>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={styles["checkout-table"]}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("cart_label_name")}</TableCell>
                  <TableCell>{t("cart_label_quantity")}</TableCell>
                  <TableCell>{t("cart_label_price")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.data.order?.Items?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {item.Catalog_Product["Basic.Name"]}
                      </TableCell>
                      <TableCell>
                        {item.Catalog_Product["Meta.quantity"]}
                      </TableCell>
                      <TableCell>
                        {item.Catalog_Product.Price.display}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={7}></Grid>
        <Grid item xs={12} md={5}>
          <TableContainer>
            <Table className={styles["checkout-table"]}>
              <TableBody>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_subtotal")}
                  </TableCell>
                  <TableCell align="center">
                    {order.data.order.Total.display}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_tax")}
                  </TableCell>
                  <TableCell align="center">
                    {order.data.order.Vat_Amount.display}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" align="center">
                    {t("cart_label_total")}
                  </TableCell>
                  <TableCell align="center">
                    {order.data.order.Total_Vat.display}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderList;
