import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// style
import styles from "./List.module.scss";

const ReserveList = ({ event, currentSeat }) => {
  const { t } = useTranslation();

  return (
    <>
      {currentSeat !== "" && (
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table className={styles["checkout-table"]}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("cart_label_name")}</TableCell>
                    <TableCell>
                      {t("cart_label_price")}：{t("common_tax_include")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {event.data.Label}
                      <br />
                      {currentSeat[0].Seat_Name}
                    </TableCell>
                    <TableCell>
                      {currentSeat[0].Price.tax.display_short}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReserveList;
