import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import _ from "lodash";
import { error } from "components/Toast/Toast";

// material ui
import { Typography } from "@material-ui/core";

// component
import SelectInput from "components/Forms/SelectInput";
import Button from "components/Btns/Btn";

// styles
import styles from "../../MyPage.module.scss";

// const

const EditLocation = ({ billingRefresh, billing, setEdit }) => {
  const { t } = useTranslation();

  const [load, setLoad] = useState(false);

  const [userLocations] = useRest(
    "User/@/Location",
    {
      page_no: 1,
      results_per_page: 100,
      sort: { Created: "DESC" },
    },
    true
  );

  const [locationArray, setLocationArray] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(
    billing.User_Location__
  );
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (userLocations === null) return;
    const locationArray = _.map(userLocations.data, (val) => {
      return {
        label: `${val.Display[0]}${val.Display[1]}${val.Display[2]}${
          val.Display[3] === void 0 ? "" : val.Display[3]
        }
      ${val.Display[4] === void 0 ? "" : val.Display[4]}`,
        val: val.User_Location__,
      };
    });

    setLocationArray(locationArray);
  }, [userLocations]);

  const updateBilling = () => {
    setLoad(true);
    rest(`User/Billing/${billing.User_Billing__}`, "PATCH", {
      User_Location__: currentLocation,
    })
      .then(() => {
        setLoad(false);
        billingRefresh();
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (currentLocation === billing.User_Location__) {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [currentLocation]);

  return (
    <>
      {locationArray !== null && locationArray.length !== 0 && (
        <>
          <Typography
            className={styles["member-ship-modal-title"]}
            align="left"
            variant="h6"
            component="h2"
            style={{
              marginBottom: "20px",
            }}
          >
            {t("mypage_billign_edit_location")}
          </Typography>
          <SelectInput
            name="locations"
            label={t("mypage_label_locations")}
            value={currentLocation}
            array={locationArray}
            handleChange={setCurrentLocation}
            disabled={load}
            required={true}
            addClass="mgt-pc--30 mgt-sp--40"
          />

          <div className={styles["member-ship-modal-btns-group"]}>
            <Button
              onClick={() => {
                setEdit(false);
              }}
              iconPosition="noting"
            >
              {t("mypage_modal_btn_close")}
            </Button>
            <Button
              onClick={() => {
                updateBilling();
                setEdit(false);
              }}
              iconPosition="noting"
              disabled={!enable}
            >
              {t("mypage_billign_edit_location_btn")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default EditLocation;
