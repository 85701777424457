import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useHistory } from "react-router-dom";

// component
import HelmetComponents from "components/Helmet/Helmet";
import InputForm from "components/Forms/InputForm";
import Button from "components/Btns/Btn";
import CheckBox from "components/Forms/CheckBox";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import { error } from "components/Toast/Toast";

// utils
import { validateEmail } from "utils/Validation";

// icon

// style
import styles from "./Transfer.module.scss";

// img

const Transfer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const userRefresh = useRestRefresh("User:get");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [tos, setTos] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const transferRequest = (event) => {
    event.preventDefault();
    setLoading(true);

    rest("Temporary/YukiHayashi:login", "POST", {
      email: email,
      password: password,
    })
      .then(() => {
        userRefresh();
        setTimeout(() => {
          history.push("/subscribe?transition=comp");
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        error(t("error_transfer_submit_error"), {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const labelTxt = (
    <>
      <a
        href="https://www.yukihayashi.net/pages/tos"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("transfer_tos")}
      </a>
    </>
  );

  useEffect(() => {
    if (
      !validateEmail(email) ||
      !tos ||
      !email.trim() ||
      !password.trim() ||
      !password2.trim() ||
      password !== password2
    ) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [email, password, password2, tos]);

  return (
    <>
      <HelmetComponents>
        <title>
          {t("transfer_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/transfer`}
        />
        <meta
          property="og:title"
          content={`${t("transfer_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/transfer`}
        />
      </HelmetComponents>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <h2 className="c-ttl-2">
            <span
              style={{
                lineHeight: "1.1",
              }}
              className="c-ttl-2__main"
            >
              {t("transfer_title")}
            </span>
          </h2>
          <div className={`${styles["content-container"]}`}>
            <div className={styles["transfer-container"]}>
              <p
                className={styles["transfer-text"]}
                dangerouslySetInnerHTML={{ __html: t("transfer_text") }}
              />
              <form
                className={styles["form"]}
                onSubmit={(e) => transferRequest(e)}
              >
                <div className={styles["item"]}>
                  <InputForm
                    type="email"
                    value={email}
                    name="email"
                    label={t("transfer_email")}
                    placeholder={t("input_field_placeholder", {
                      text: t("transfer_email"),
                    })}
                    handleChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required={true}
                    disabled={loading}
                  />
                </div>

                <div className={styles["item"]}>
                  <InputForm
                    type="password"
                    value={password}
                    name="password"
                    label={t("transfer_password")}
                    placeholder={t("input_field_placeholder", {
                      text: t("transfer_password"),
                    })}
                    handleChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required={true}
                    disabled={loading}
                  />
                </div>

                <div className={styles["item"]}>
                  <InputForm
                    type="password"
                    value={password2}
                    name="password2"
                    label={t("transfer_password_2")}
                    placeholder={t("input_field_placeholder", {
                      text: t("transfer_password_2"),
                    })}
                    handleChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                    required={true}
                    disabled={loading}
                  />
                </div>

                <div className={styles["item"]}>
                  <CheckBox
                    value={tos}
                    name="tos"
                    label={labelTxt}
                    handleChange={(e) => {
                      setTos(e.target.checked);
                    }}
                    disabled={loading}
                  />
                </div>

                <div id="confirm1" className="c-btn-3 mgt-pc--50 mgt-sp--50">
                  <Button disabled={disabled} btnTypes="submit">
                    {t("btn_next")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingOverLay />}
    </>
  );
};

export default Transfer;
