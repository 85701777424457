import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

// material ui

// component
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Toast, { error } from "components/Toast/Toast";
import Button from "components/Btns/Btn";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import BillingInputs from "components/Billing/BillingInputs";
import CartList from "components/List/CartList";

// style
import styles from "./Checkout.module.scss";

const CheckOut = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const [cart] = useRest("Catalog/Cart/@");
  const history = useHistory();

  // load
  const [load, setLoad] = useState(false);

  // form
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);

  //max

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // form input check
  useEffect(() => {
    if (
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  const createLocation = (e) => {
    if (e) e.preventDefault();

    setLoad(true);

    const params = {
      Billing: {
        First_Name: fName,
        Last_Name: lName,
        Zip: zip,
        Country__: country,
        Address: address,
        City: city,
        Province: province,
        Contact_Phone: phone,
      },
    };

    rest("Catalog/Cart/@:createOrder", "POST", params)
      .then((data) => {
        setLoad(false);
        history.push("/order/" + data.data.Order__);
      })
      .catch((data) => {
        setLoad(false);
        setTimeout(() => {
          error(data.message, {
            position: "top-center",
            autoClose: 3000,
          });
        }, 100);
      });
  };

  return (
    <>
      <Helmet>
        <title>{`${t("common_checkout")} - ${t("ccommon_meta_title")}`}</title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/checkout`}
        />
        <meta
          property="og:title"
          content={`${t("common_checkout")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/checkout`}
        />
      </Helmet>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <h3 className="c-ttl-5">{t("common_checkout")}</h3>
            {cart === null && user === null && <Loading />}
            {cart !== null && user !== null && (
              <>
                <CartList cart={cart} deleteBtn={false} />
              </>
            )}
          </div>
        </div>
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <BillingInputs
              fName={fName}
              setFName={setFName}
              lName={lName}
              setLName={setLName}
              zip={zip}
              setZip={setZip}
              province={province}
              setProvince={setProvince}
              city={city}
              setCity={setCity}
              address={address}
              setAddress={setAddress}
              phone={phone}
              setPhone={setPhone}
              country={country}
              setCountry={setCountry}
              geoVal={geoVal}
              Countrys={Countrys}
              formEnabled={formEnabled}
              setFormEnabled={setFormEnabled}
              load={load}
            />
            <div className={styles["reserve-btns"]}>
              <Button
                disabled={!formEnabled || load}
                onClick={() => {
                  createLocation();
                }}
              >
                {t("common_checkout_order")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {load && <LoadingOverLay />}
      <Toast duration={5000} />
    </>
  );
};

export default CheckOut;
