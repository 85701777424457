import React, { useEffect, useState } from "react";
import { rest } from "@karpeleslab/klbfw";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { error, success } from "components/Toast/Toast";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

// component
import LoadingElements from "components/Loading/LoadingElements";
import StripeForm from "./StripeForm";
import KonbiniDisplay from "./KonbiniDisplay";

// styles
import styles from "./Payment.module.scss";

// const
import { BILLING_LABEL } from "const/const";

const StripeWrapper = ({
  type = "mypage",
  billing = null,
  orderProcess = null,
  setEdit = false,
  fName = "",
  lName = "",
  zip = "",
  country = "",
  address = "",
  city = "",
  province = "",
  phone = "",
  formEnabled = false,
  load = false,
  setLoad = null,
  cartRefresh = null,
  billingRefresh = null,
  gender = null,
  birth = null,
  selectedPlan = null,
  setSubscribeComp = null,
  addClass = null,
  orderProcessRefresh = null,
  setOrderComp = null,
  settings = null,
  setIntent = null,
  setOrderProcess = null,
  setAddModal = null,
}) => {
  const { t } = useTranslation();
  // stripe
  const [stripePromise, setStripePromise] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(settings);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeIntent, setStripeIntent] = useState(null);

  const [ccRemember, setCcRemember] = useState(false);

  const [intentStatus, setIntentStatus] = useState(null);

  // user
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  // next_action
  const [nextAction, setNextAction] = useState(false);

  const setLocation = async () => {
    if (type !== "fanclub") {
      const data = await rest(`User/Location/${billing.User_Location__}`);
      setCurrentLocation(data);
    }
    const user = await rest("User");
    setCurrentUser(user);
  };

  useEffect(() => {
    if (settings) return;
    Promise.all([
      rest("Realm/PaymentMethod:setup", "POST", { method: "stripe" }),
      rest("Order/Payment:methodInfo", "GET", { method: "stripe" }),
    ]).then((d) => {
      const intentResult = d[0].data;
      const infoResult = d[1].data;
      setStripeIntent(intentResult.Setup);
      setPaymentInfo({
        stripe_intent: {
          attributes: { client_secret: intentResult.Setup.client_secret },
        },
        cc_token: infoResult.Fields.cc_token,
      });
    });
    setLocation();
  }, [setPaymentInfo, settings, setIntent]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    setLoad(true);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
            method: orderProcess.data.methods.Stripe.method,
            session: orderProcess.data.methods.Stripe.session,
            stripe_intent: 1,
          })
            .then(() => {
              setLoad(false);
              cartRefresh();
              setOrderComp(true);
              orderProcessRefresh();
            })
            .catch((err) => {
              setLoad(false);
              error(err.message, {
                position: "top-center",
                autoClose: 3000,
              });
            });
          break;
        case "processing":
          setLoad(false);
          error(t("payment_processing"), {
            position: "top-center",
            autoClose: 3000,
          });
          break;
        case "requires_payment_method":
          setLoad(false);
          error(t("requires_payment_method"), {
            position: "top-center",
            autoClose: 3000,
          });
          break;
        default:
          setLoad(false);
          error(t("unexpected_error"), {
            position: "top-center",
            autoClose: 3000,
          });
          break;
      }
    });
    // eslint-disable-next-line
	}, [stripe]);

  // set
  useEffect(() => {
    if (paymentInfo === null) return;
    setClientSecret(paymentInfo.stripe_intent.attributes.client_secret);

    setStripePromise(
      loadStripe(paymentInfo.cc_token.attributes.key, {
        stripeAccount: paymentInfo.cc_token.attributes.options.stripe_account,
      })
    );
  }, [paymentInfo]);

  const stripeError = (message) => {
    error(message);
  };

  // update
  const updateSubmit = async (stripe, stripeElements) => {
    setLoad(true);

    if (
      !stripe ||
      !stripeElements ||
      currentLocation === null ||
      currentUser === null
    )
      return; // stripe not loaded yet

    const result = await stripe.confirmSetup({
      elements: stripeElements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${currentLocation.data.First_Name} ${currentLocation.data.Last_Name}`,
            email: currentUser.data[0].Email,
            address: {
              country: currentLocation.data.Country__,
              postal_code: currentLocation.data.Zip,
              state: currentLocation.data.Province ?? "",
              city: currentLocation.data.City ?? "",
              line1: currentLocation.data.Address ?? "",
              line2: currentLocation.data.Address2 ?? "",
            },
          },
        }, // Make sure to change this to your payment completion page
        //return_url: `https://${window.location.host}${getPXLOrderRoute(order.Order__)}`,
      },
    });

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        error(result.error.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        error("An unexpected error occurred.", false, {
          position: "top-center",
          autoClose: 3000,
        });
      }
      return;
    }

    rest(
      `User/Billing/Method/${billing.Methods[0].User_Billing_Method__}:change`,
      "POST",
      {
        stripe_intent: stripeIntent.stripe_intent,
        method: "Stripe",
      }
    )
      .then(() => {
        success(t("toast_success_text"), true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            billingRefresh();
          },
        });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // mypage
  const mypageSubmit = (token) => {
    setLoad(true);
    rest("User/@/Location", "POST", {
      First_Name: fName,
      Last_Name: lName,
      Zip: zip,
      Country__: country,
      Address: address,
      City: city,
      Province: province,
      Contact_Phone: phone,
    })
      .then((data) => {
        rest("User/@/Billing:create", "POST", {
          Label: BILLING_LABEL,
          User_Location__: data.data.User_Location__,
          cc_token: token.token.id,
          method: "Stripe",
        })
          .then(() => {
            success("toast_billing_save", true, {
              position: "top-center",
              autoClose: 1500,
              onClose: () => {
                billingRefresh();
              },
            });
          })
          .catch((err) => {
            setLoad(false);
            error(err.message, false, {
              position: "top-center",
              autoClose: 3000,
            });
          });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // order
  const orderSubmit = async (stripe, stripeElements) => {
    if (!stripe || !stripeElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoad(true);
    const result = await stripe.confirmPayment({
      elements: stripeElements,
      redirect: "if_required",
      confirmParams: {
        //setup_future_usage: ccRemember ? 'off_session' : undefined,
        payment_method_data: {
          billing_details: {
            name: `${lName} ${fName}`,
            email: orderProcess.data.order.User.Email,
            address: {
              country: orderProcess.data.order.Billing_User_Location.Country__,
              postal_code: zip,
              state: province,
              city: city,
              line1: address,
            },
          },
        }, // Make sure to change this to your payment completion page
        return_url: `https://${window.location.host}/order/${orderProcess.data.order.Order__}`,
      },
    });

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        error(result.error.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        error(t("unexpected_error"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoad(false);
      return;
    }

    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.Stripe.method,
      session: orderProcess.data.methods.Stripe.session,
      stripe_intent: 1,
    })
      .then(() => {
        setLoad(false);
        cartRefresh();
        setOrderComp(true);
        orderProcessRefresh();
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  // subscribe
  const subscribeSubmit = async (stripe, stripeElements) => {
    if (!stripe || !stripeElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoad(true);

    const result = await stripe.confirmSetup({
      elements: stripeElements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${lName} ${fName}`,
            email: currentUser.data[0].Email,
            address: {
              country: country,
              postal_code: zip,
              state: province,
              city: city,
              line1: address,
            },
          },
        }, // Make sure to change this to your payment completion page
        // return_url: `https://${window.location.host}/order/${orderProcess.data.order.Order__}`,
      },
    });

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        error(result.error.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        error(t("unexpected_error"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoad(false);
      return;
    }

    try {
      const profile = {
        Gender: gender,
        Birthdate: birth,
      };

      await rest("User/@/Profile", "PATCH", profile);
      const locationResult = await rest("User/@/Location", "POST", {
        First_Name: fName,
        Last_Name: lName,
        Zip: zip,
        Country__: country,
        Address: address,
        City: city,
        Province: province,
        Contact_Phone: phone,
      });

      const billingResult = await rest("User/@/Billing:create", "POST", {
        Label: BILLING_LABEL,
        User_Location__: locationResult.data.User_Location__,
        stripe_intent: stripeIntent.stripe_intent,
        method: "Stripe",
      });

      await rest(
        `Membership/Plan/${selectedPlan.Membership_Plan__}:subscribe`,
        "POST",
        {
          User_Billing__: billingResult.data.User_Billing__,
        }
      );

      setLoad(false);
      setSubscribeComp(true);
    } catch (err) {
      setLoad(false);
      error(err.message, false, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  // add
  const addBilling = async (stripe, stripeElements) => {
    if (!stripe || !stripeElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    console.log(stripeElements);

    setLoad(true);

    const result = await stripe.confirmSetup({
      elements: stripeElements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${currentLocation.data.First_Name} ${currentLocation.data.Last_Name}`,
            email: currentUser.data[0].Email,
            address: {
              country: currentLocation.data.Country__,
              postal_code: currentLocation.data.Zip,
              state: currentLocation.data.Province ?? "",
              city: currentLocation.data.City ?? "",
              line1: currentLocation.data.Address ?? "",
              line2: currentLocation.data.Address2 ?? "",
            },
          },
        }, // Make sure to change this to your payment completion page
        // return_url: `https://${window.location.host}/order/${orderProcess.data.order.Order__}`,
      },
    });

    if (result.error) {
      if (
        result.error.type === "card_error" ||
        result.error.type === "validation_error"
      ) {
        error(result.error.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        error(t("unexpected_error"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoad(false);
      return;
    }

    try {
      await rest("User/@/Billing:create", "POST", {
        Label: BILLING_LABEL,
        User_Location__: currentLocation.data.User_Location__,
        stripe_intent: stripeIntent.stripe_intent,
        method: "Stripe",
      });
      setLoad(false);
      setAddModal(false);
      billingRefresh();
    } catch (err) {
      setLoad(false);
      error(err.message, false, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const settingPropsObject = () => {
    let commonObj = {
      stripeError: stripeError,
      type: type,
      load: load,
      setLoad: setLoad,
      ccRemember: ccRemember,
      setCcRemember: setCcRemember,
      intentStatus: intentStatus,
    };

    switch (type) {
      case "withBilling":
        commonObj["submitForm"] = mypageSubmit;
        commonObj["formEnabled"] = formEnabled;
        break;
      case "update":
        commonObj["submitForm"] = updateSubmit;
        commonObj["setEdit"] = setEdit;
        break;
      case "order":
        commonObj["submitForm"] = orderSubmit;
        break;
      case "fanclub":
        commonObj["submitForm"] = subscribeSubmit;
        break;
      case "add":
        commonObj["submitForm"] = addBilling;
        break;
      default:
        break;
    }

    return commonObj;
  };

  useEffect(async () => {
    if (orderProcess === null) {
      setNextAction(null);
      setIntentStatus(false);
    } else {
      const ordeProcessArray = await rest(
        `Order/${orderProcess.data.order.Order__}:process`,
        "POST",
        {
          method: orderProcess.data.methods.Stripe.method,
          session: orderProcess.data.methods.Stripe.session,
          stripe_intent: 1,
        }
      );

      setNextAction(
        ordeProcessArray.data.methods.Stripe.result.intent.next_action
      );

      setIntentStatus(
        ordeProcessArray.data.methods.Stripe.result.intent?.status !== void 0
          ? ordeProcessArray.data.methods.Stripe.result.intent?.status
          : false
      );
    }
  }, [orderProcess]);

  useEffect(() => {
    if (type !== "order" && intentStatus !== "requires_payment_method") return;

    setLoad(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.Stripe.method,
      session: orderProcess.data.methods.Stripe.session,
      stripe_intent: 1,
      cc_remember: ccRemember ? 1 : 0,
    })
      .then((d) => {
        setLoad(false);
        setOrderProcess(d);
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, {
          position: "top-center",
          autoClose: 3000,
        });
      });
    // eslint-disable-next-line
	}, [ccRemember, intentStatus])

  const appearance = {
    theme: "stripe",
    variables: {
      borderRadius: "30px",
      spacingUnit: "6px",
    },

    rules: {
      ".Input": {
        border: "1px solid #595757",
        backgroundColor: "transparent",
      },
      ".Label": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595757",
        marginBottom: "12px",
        width: "100%",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className={clsx({
        ["mgt-pc--30 mgt-sp--40"]: addClass !== null,
      })}
    >
      {stripePromise === null && (
        <LoadingElements className={styles["payment-load-elements"]} />
      )}
      {stripePromise !== null && (
        <>
          {nextAction !== null && nextAction !== false && (
            <KonbiniDisplay konbiniInfo={nextAction} />
          )}
          {nextAction === null && nextAction !== false && (
            <>
              <h3
                className="c-ttl-5"
                style={{
                  marginBottom: "20px",
                }}
              >
                {t("common_credit_inputs_title")}
              </h3>
              <Elements stripe={stripePromise} options={options}>
                <StripeForm setStripe={setStripe} {...settingPropsObject()} />
              </Elements>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default StripeWrapper;
