import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";

// component
import InputForm from "components/Forms/Input";
import Country from "components/Forms/Country";
import Provinces from "components/Forms/Provinces";

const BillingInputs = ({
  fName,
  setFName,
  lName,
  setLName,
  zip,
  setZip,
  province,
  setProvince,
  city,
  setCity,
  address,
  setAddress,
  phone,
  setPhone,
  country,
  setCountry,
  Countrys,
  load,
  type = "default",
}) => {
  const { t } = useTranslation();
  const [displayFormat, setDisplayFormat] = useState(null);
  const [displayProvinces, setDisplayProvinces] = useState(null);

  useEffect(() => {
    if (type === "detault") setProvince("");

    if (country !== "") {
      rest(`Country/${country}`).then((data) => {
        setDisplayFormat(data.data.Display_Format);
      });

      rest(`Country/${country}:getProvinces`)
        .then((data) => {
          setDisplayProvinces(data);
        })
        .catch(() => {
          setDisplayProvinces({
            data: [],
          });
        });
    }
  }, [country]);

  const viewFormat = () => {
    return displayFormat.map((array) => {
      return array.map((item, index) => {
        switch (item) {
          case "!":
          case "!〒":
          case "Address1":
            break;
          case "Zip":
            return (
              <InputForm
                key={index}
                type="text"
                value={zip}
                name="user-zip"
                label={t("mypage_billing_zip")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_zip"),
                })}
                handleChange={setZip}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
            );
          case "Province":
            return (
              <div key={index}>
                {displayProvinces !== null && (
                  <>
                    {displayProvinces?.data.length !== 0 && (
                      <Provinces
                        name="user-province"
                        label={t("mypage_billing_province")}
                        value={province}
                        array={displayProvinces?.data}
                        handleChange={(event) => {
                          setProvince(event.target.value);
                        }}
                        addClass="mgt-pc--30 mgt-sp--40"
                        required={true}
                        disabled={load}
                      />
                    )}
                    {displayProvinces?.data.length === 0 && (
                      <InputForm
                        type="text"
                        value={province}
                        name="user-province"
                        label={t("mypage_billing_province")}
                        handleChange={setProvince}
                        addClass="mgt-pc--30 mgt-sp--40"
                        required={true}
                        disabled={load}
                      />
                    )}
                  </>
                )}
              </div>
            );
          case "City":
            return (
              <InputForm
                key={index}
                type="text"
                value={city}
                name="user-city"
                label={t("mypage_billing_city")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_city"),
                })}
                handleChange={setCity}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
            );
          case "Address":
            return (
              <InputForm
                key={index}
                type="text"
                value={address}
                name="user-address"
                label={t("mypage_billing_address")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_address"),
                })}
                handleChange={setAddress}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
            );
          default:
            break;
        }
      });
    });
  };

  return (
    <>
      {displayFormat !== null && (
        <>
          <Country
            name="user-country"
            label={t("mypage_billing_country")}
            value={country}
            array={Countrys?.data}
            handleChange={(event) => {
              setCountry(event.target.value);
            }}
            addClass="mgt-pc--30 mgt-sp--40"
            required={true}
            disabled={load}
          />
          {country === "JP" && (
            <>
              <InputForm
                type="text"
                value={lName}
                name="user-lname"
                label={t("mypage_billing_lname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_lname"),
                })}
                handleChange={setLName}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
              <InputForm
                type="text"
                value={fName}
                name="user-fname"
                label={t("mypage_billing_fname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_fname"),
                })}
                handleChange={setFName}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
            </>
          )}
          {country !== "JP" && (
            <>
              <InputForm
                type="text"
                value={fName}
                name="user-fname"
                label={t("mypage_billing_fname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_fname"),
                })}
                handleChange={setFName}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
              <InputForm
                type="text"
                value={lName}
                name="user-lname"
                label={t("mypage_billing_lname")}
                placeholder={t("input_field_placeholder", {
                  text: t("mypage_billing_lname"),
                })}
                handleChange={setLName}
                addClass="mgt-pc--30 mgt-sp--40"
                required={true}
                disabled={load}
              />
            </>
          )}

          {viewFormat()}

          <InputForm
            type="tel"
            value={phone}
            name="user-phone"
            label={t("mypage_billing_phone")}
            placeholder={t("input_field_placeholder", {
              text: t("mypage_billing_phone"),
            })}
            addClass="mgt-pc--30 mgt-sp--40"
            handleChange={setPhone}
            required={true}
            disabled={load}
          />
        </>
      )}
    </>
  );
};

export default BillingInputs;
