import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getPrefix } from "@karpeleslab/klbfw";
import _ from "lodash";
import clsx from "clsx";
import NoSsr from "@material-ui/core/NoSsr";

// component
import Share from "./section/Share";
import Like from "./section/Like";
import Comment from "./section/Comment";
import HelmetComponents from "components/Helmet/Helmet";

// utils
import { buildDate } from "utils/BuildDate";

// icon

// style
import styles from "./Cms.module.scss";

// img

const DetailDisplay = ({ article, type = "news" }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const tagDisplay = (tags) => {
    const tagArray = _.map(tags, "Full_Name");
    const currentTag = _.filter(tagArray, (value) => {
      if (value !== "NEWS") {
        return value;
      }
    });

    return currentTag[0];
  };

  return (
    <>
      {article === false && (
        <>
          <HelmetComponents>
            <title>
              {t("error_not_found")} - {t("ccommon_meta_title")}
            </title>
          </HelmetComponents>
          <div className={styles["cms-article-notfound"]}>
            <p className={styles["cms-article-notfound-text"]}>
              {t("error_not_found")}
            </p>
          </div>
        </>
      )}
      {article !== false && (
        <>
          <HelmetComponents>
            <title>
              {article.data.content_cms_entry_data.Title} -{" "}
              {t("ccommon_meta_title")}
            </title>
            <link
              rel="canonical"
              href={`https://www.yukihayashi.net${getPrefix()}${
                location.pathname
              }`}
            />
            <meta
              property="og:title"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "ccommon_meta_title"
              )}`}
            />
            <meta
              property="og:url"
              content={`https://www.yukihayashi.net${getPrefix()}${
                location.pathname
              }`}
            />
          </HelmetComponents>
          <div className="c-article__head">
            <div className={styles["c-article-head"]}>
              <div>
                <div className="c-article__info">
                  {type === "news" &&
                    tagDisplay(
                      article.data.content_cms_entry_data.Content_Cms_Entry.Tags
                    ) !== void 0 && (
                      <span className="c-article__cat">
                        {tagDisplay(
                          article.data.content_cms_entry_data.Content_Cms_Entry
                            .Tags
                        )}
                      </span>
                    )}
                  <span className="c-article__date">
                    {buildDate(
                      parseInt(
                        article.data.content_cms_entry_data.Published === null
                          ? article.data.content_cms_entry_data.Created.unixms
                          : article.data.content_cms_entry_data.Published.unixms
                      ),
                      "MMM DD, YYYY"
                    )}
                  </span>
                </div>
                <h4 className="c-article__ttl">
                  {article.data.content_cms_entry_data.Title}
                </h4>
              </div>
              {type === "fanclub" && (
                <Like
                  targetId={
                    article.data.content_cms_entry_data.Content_Cms_Entry__
                  }
                  likes={article.data.content_cms_entry_data.Likes}
                />
              )}
            </div>
          </div>

          <div className="c-article__content">
            {article.data.content_cms_entry_data.Drive_Item__ !== null && (
              <p style={{ textAlign: "center" }}>
                <img
                  src={
                    article.data.content_cms_entry_data.Top_Drive_Item
                      .Media_Image.Url
                  }
                  alt={article.data.content_cms_entry_data.Title}
                />
              </p>
            )}
            <div
              className="wysiwyg"
              dangerouslySetInnerHTML={{
                __html: article.data.content_cms_entry_data.Contents,
              }}
            />
          </div>
        </>
      )}

      {type === "fanclub" && (
        <NoSsr>
          <Comment cmsEntry={article.data.content_cms_entry_data} />
        </NoSsr>
      )}
      <div
        className={clsx("c-article__foot", {
          [styles["c-article__foot__center"]]: article === false,
        })}
      >
        <span
          className="c-article__btn"
          onClick={() => {
            history.goBack();
          }}
        >
          {t("common_back_to_list")}
        </span>
        {article !== false && <Share article={article} />}
      </div>
    </>
  );
};

export default DetailDisplay;
