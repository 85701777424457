import React from "react";
import { useTranslation } from "react-i18next";

// component
import MoreBtn from "components/Btns/MoreBtn";
import ThumbnailItem from "./ThumbnailItem";

// style
import styles from "./Works.module.scss";

// img

const Thumbnails = ({
  paging = 1,
  currentPage = 1,
  setCurrentPage = null,
  works,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {works.length === 0 && (
        <p className={styles["notfound-text"]}>{t("error_not_found")}</p>
      )}
      {works.length !== 0 && (
        <div className="c-list-circle js-ItemList">
          {works.map((val, index) => {
            return <ThumbnailItem key={index} val={val} />;
          })}
          {paging.page_max > paging.page_no && (
            <MoreBtn
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Thumbnails;
