import React from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import Toast, { error } from "components/Toast/Toast";
import clsx from "clsx";

// component
import Button from "components/Btns/Btn";
// styles

// const

const Free = ({
  orderProcess = null,
  setLoad = null,
  cartRefresh = null,
  addClass = null,
  orderProcessRefresh = null,
  setOrderComp = null,
}) => {
  const { t } = useTranslation();

  // order
  const orderSubmit = () => {
    setLoad(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, "POST", {
      method: orderProcess.data.methods.Free.method,
      session: orderProcess.data.methods.Free.session,
    })
      .then(() => {
        setLoad(false);
        cartRefresh();
        setOrderComp(true);
        orderProcessRefresh();
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <div
      className={clsx({
        ["mgt-pc--30 mgt-sp--40"]: addClass !== null,
      })}
    >
      <Button
        onClick={() => {
          orderSubmit();
        }}
      >
        {t("order_free")}
      </Button>
      <Toast duration={5000} />
    </div>
  );
};

export default Free;
