import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import clsx from "clsx";

// component
import SlideUp from "components/SlideUp/SlideUp";

// style
// import styles from "../Index.module.scss";

// img
import mainOff from "assets/img/img-main-0.png";
import mainOn from "assets/img/img-main-1.png";
import kuroe from "assets/img/img-animation-kuroe.gif";
import pekijiro from "assets/img/img-animation-pekijiro.gif";

const Nav = () => {
  const { t } = useTranslation();
  const imgUrls = [mainOff, mainOn];
  const [imgsLoaded, setLoaded] = useState(false);
  const [imgFlg, setImgFlg] = useState(false);

  useEffect(() => {
    let loadingCount = 0;
    for (let i = 0; i < imgUrls.length; i++) {
      const img = new Image();
      img.src = imgUrls[i];
      img.onload = () => {
        loadingCount++;
        if (loadingCount === imgUrls.length) {
          setLoaded(true);
        }
      };
    }

    switchingImg();
  }, []);

  const switchingImg = useCallback(() => {
    setTimeout(() => {
      setImgFlg(!imgFlg);
    }, 1000);
  }, [imgFlg]);

  useEffect(() => {
    if (imgsLoaded) {
      const timeOut = setTimeout(() => {
        setImgFlg(!imgFlg);
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [imgFlg, imgsLoaded]);

  return (
    <div
      className={clsx("c-block js-fadeup", {
        ["is-fadeup"]: imgsLoaded,
      })}
    >
      <div className="c-block__container">
        <h1 className="c-ttl">
          <span className="c-ttl__main">
            <SlideUp>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("mainNav_profile_name_animation"),
                }}
              />
            </SlideUp>
          </span>
          <span className="c-ttl__sub">{t("common_logo_sub")}</span>
        </h1>

        <div className="c-block__img">
          <img
            src={mainOff}
            style={{
              display: !imgFlg ? "block" : "none",
            }}
            alt=""
          />
          <img
            src={mainOn}
            style={{
              display: imgFlg ? "block" : "none",
            }}
            alt=""
          />
        </div>
        <Link
          smooth={true}
          to="works"
          offset={-95}
          duration={400}
          className="c-block__link c-block__link--work sp-hide"
        >
          <span>{t("mainNav_works")}</span>
        </Link>
        <Link
          smooth={true}
          to="profile"
          offset={-95}
          duration={400}
          className="c-block__link c-block__link--profile sp-hide"
        >
          <span>{t("mainNav_profile")}</span>
        </Link>
        <Link
          smooth={true}
          to="contact"
          offset={-95}
          duration={400}
          className="c-block__link c-block__link--contact sp-hide"
        >
          <span>{t("mainNav_contact")}</span>
        </Link>
        <Link
          smooth={true}
          to="new"
          offset={-95}
          duration={400}
          className="c-block__link c-block__link--new sp-hide"
        >
          <span dangerouslySetInnerHTML={{ __html: t("common_new") }}></span>
        </Link>
        <div className="c-block__chara c-block__chara--left">
          <img src={kuroe} alt="" />
        </div>
        <div className="c-block__chara c-block__chara--right">
          <img src={pekijiro} alt="" />
        </div>
        <div className="c-block__scroll">
          <span className="c-block__scroll-txt">{t("mainNav_scroll")}</span>
          <span className="c-block__scroll-line"></span>
        </div>
      </div>
    </div>
  );
};

export default Nav;
