import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getLocale } from "@karpeleslab/klbfw";
import Toast from "components/Toast/Toast";

// material ui

// component
import Default from "./section/Default";
import HelmetComponents from "components/Helmet/Helmet";

// const

export default ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const buildRoutes = () => {
    return (
      <div>
        <HelmetComponents>
          <meta charSet="utf-8" />
          <title>{t("ccommon_meta_title")}</title>
          <meta name="description" content={t("ccommon_meta_description")} />
          <link rel="canonical" href="https://www.yukihayashi.net/" />
          <meta property="og:locale" content={getLocale().replace("-", "_")} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={t("ccommon_meta_title")} />
          <meta
            property="og:description"
            content={t("ccommon_meta_description")}
          />
          <meta property="og:url" content="https://www.yukihayashi.net/" />
          <meta
            property="og:site_name"
            content={t("ccommon_meta_description")}
          />
          <meta
            property="og:image"
            content="https://www.yukihayashi.net/img/img-main.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="1022" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:image"
            content="https://www.yukihayashi.net/img/img-main.png"
          />
        </HelmetComponents>
        <Default>{children}</Default>
        <Toast duration={5000} />
      </div>
    );
  };

  return <>{buildRoutes()}</>;
};
