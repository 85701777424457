import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { getPrefix } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import qs from "qs";
import { error } from "components/Toast/Toast";

// material ui

// component
import HelmetComponents from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";
import FadeUp from "components/FadeUp/FadeUp";
import CartList from "components/List/CartList";

// style
import styles from "./Cart.module.scss";

const Cart = () => {
  const { t } = useTranslation();
  const [cart] = useRest("Catalog/Cart/@");
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const location = useLocation();
  const hash = qs.parse(location.search.split("?")[1]);
  const history = useHistory();

  useEffect(() => {
    addToCart(hash.product);
  }, []);

  const addToCart = (hash) => {
    if (hash === void 0) {
      return false;
    }

    const query = {
      request: hash,
    };

    rest("Catalog/Cart/@:process", "POST", query)
      .then(() => {
        cartRefresh();
      })
      .catch((err) => {
        error(`error_${err.token}`, true, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <HelmetComponents>
        <title>
          {t("cart_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/cart`}
        />
        <meta
          property="og:title"
          content={`${t("cart_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/cart`}
        />
      </HelmetComponents>
      <FadeUp>
        <div className="c-block">
          <div className="c-block__container">
            <div className={`${styles["content-wrapper"]}`}>
              <h3 className="c-ttl-5">{t("cart_title")}</h3>
              <div className={styles["content-container"]}>
                {cart === null && <Loading />}
                {cart !== null && cart?.data.products.length === 0 && (
                  <>
                    <p className={styles["cart-item-no"]}>
                      {t("cart_label_no_item")}
                    </p>
                    <div className={styles["cart-btns"]}>
                      <Button
                        iconPosition="start"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {t("paginate_prev")}
                      </Button>
                    </div>
                  </>
                )}
                {cart !== null && cart?.data.products.length !== 0 && (
                  <>
                    <CartList cart={cart} deleteBtn={true} />
                    <div className={styles["cart-btns"]}>
                      <Button
                        onClick={() => {
                          history.push("/checkout");
                        }}
                      >
                        {t("cart_label_to_checkout")}
                      </Button>
                      <Button to="/goods" btnTypes="link">
                        {t("cart_btn_continue")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </FadeUp>
    </>
  );
};

export default Cart;
