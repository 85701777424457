import React from "react";
import { useTranslation } from "react-i18next";
import { getPrefix } from "@karpeleslab/klbfw";

// component
import List from "./section/List";
import FadeUp from "components/FadeUp/FadeUp";
import HelmetComponents from "components/Helmet/Helmet";

// style

// img

const Info = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetComponents>
        <title>
          {t("information_title_ja")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/info`}
        />
        <meta
          property="og:title"
          content={`${t("information_title_ja")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/info`}
        />
      </HelmetComponents>
      <FadeUp>
        <div className="c-block">
          <div className="c-block__container">
            <section className="c-section-6">
              <h3 className="c-ttl-5">{t("information_title")}</h3>

              <div className="c-section-6__content">
                <List />
              </div>
            </section>
          </div>
        </div>
      </FadeUp>
    </>
  );
};

export default Info;
