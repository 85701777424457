import React from "react";

// material ui
import CircularProgress from "@material-ui/core/CircularProgress";

// style
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles["loading-container"]}>
      <CircularProgress className={styles["loading"]} color="secondary" />
    </div>
  );
};

export default Loading;
