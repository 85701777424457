import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { getLocale } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { animateScroll, scroller } from "react-scroll";
// import { rest } from "@karpeleslab/klbfw";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

import clsx from "clsx";

// style
import styles from "./Header.module.scss";

// img
import Logo from "assets/img/logo.svg";
import HeaderBg from "assets/img/bg-background-default.jpg";

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const defaultLng = getLocale();
  const [viewLang, setViewLang] = useState(false);
  const [viewMenu, setViewMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const location = useLocation();
  const header = useRef();

  const langClick = (boolean, lang) => {
    if (boolean) {
      setViewLang(!viewLang);
    } else {
      window.location.href = `/l/${lang}${location.pathname}`;
    }
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop({ duration: 400 });
  };

  const scrollCheck = () => {
    if (typeof window !== "undefined") {
      const position =
        document.body.scrollTop || document.documentElement.scrollTop;
      setScroll(position !== 0);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // scroll
      if (header.current !== null) {
        setTimeout(() => {
          const headerHeight = header.current.offsetHeight;
          scroller.scrollTo(location.hash.replace("#", ""), {
            duration: 400,
            delay: 1000,
            smooth: "swing",
            offset: parseInt(`-${headerHeight}`),
          });
        }, 500);

        scrollCheck();
        window.addEventListener("scroll", scrollCheck);
        return () => {
          window.removeEventListener("scroll", scrollCheck);
        };
      }
    }
  }, [location.pathname]);

  const [user] = useRest("User:get");
  // const userRefresh = useRestRefresh("User:get");

  // const logOut = () => {
  //   rest("User/@:logout", "POST").then(() => {
  //     userRefresh();
  //   });
  // };

  const hashScroll = (elementName) => {
    setViewMenu(false);
    const headerHeight = header.current.offsetHeight;
    history.push(`/#${elementName}`);
    scroller.scrollTo(elementName, {
      duration: 400,
      delay: 0,
      smooth: "swing",
      offset: parseInt(`-${headerHeight}`),
    });
  };

  return (
    <header
      className={clsx(styles["header"], {
        [styles["header-active"]]: scroll,
      })}
      style={{
        backgroundImage: `url(${HeaderBg})`,
      }}
      ref={header}
    >
      <h1 className={styles["header-logo"]}>
        <Link
          onClick={() => {
            scrollToTop();
          }}
          className={styles["header-logo-link"]}
          to="/"
        >
          <img
            className={clsx(styles["header-logo-link-img"], {
              [styles["header-logo-link-img-active"]]: scroll,
            })}
            src={Logo}
            alt={`${t("common_logo_main")} ${t("common_logo_sub")}`}
          />
        </Link>
        <div
          className={clsx(styles["header-logo-text"], {
            [styles["header-logo-text-active"]]: scroll,
          })}
        >
          <span className={styles["header-logo-text-main"]}>
            {t("common_logo_main")}
          </span>
          <span className={styles["header-logo-text-sub"]}>
            {t("common_logo_sub")}
          </span>
        </div>
      </h1>

      <div
        className={clsx(styles["header-nav-outer"], {
          [styles["header-nav-outer-active"]]: viewMenu,
        })}
      >
        <div className={styles["header-menu-head"]}>
          <p className={styles["header-menu-head-main"]}>
            {t("common_logo_main")}
          </p>
          <p className={styles["header-menu-head-sub"]}>
            {t("common_logo_sub")}
          </p>
        </div>
        <nav className={styles["header-nav"]}>
          <ul className={styles["header-nav-list"]}>
            <li className={styles["header-nav-item"]}>
              <Link
                onClick={() => {
                  setViewMenu(false);
                }}
                to="/info"
                className={styles["header-nav-item-link"]}
                dangerouslySetInnerHTML={{ __html: t("common_new") }}
              />
            </li>
            <li className={styles["header-nav-item"]}>
              <span
                onClick={() => {
                  hashScroll("works");
                }}
                className={styles["header-nav-item-link"]}
              >
                {t("common_works")}
              </span>
            </li>
            <li className={styles["header-nav-item"]}>
              <Link
                onClick={() => {
                  setViewMenu(false);
                }}
                to="/event"
                className={styles["header-nav-item-link"]}
                dangerouslySetInnerHTML={{ __html: t("common_event") }}
              />
            </li>
            <li className={styles["header-nav-item"]}>
              <a
                href="https://pro.form-mailer.jp/fms/1bf743f0312580"
                rel="noopener noreferrer"
                target="_blank"
                className={styles["header-nav-item-link"]}
                onClick={() => {
                  setViewMenu(false);
                }}
              >
                {t("common_contact")}
              </a>
            </li>

            {user?.data !== null && (
              <>
                <li className={styles["header-nav-item"]}>
                  <Link
                    onClick={() => {
                      setViewMenu(false);
                    }}
                    to="/mypage"
                    className={styles["header-nav-item-link"]}
                  >
                    {t("common_mypage")}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        <div className={styles["header-nav-group"]}>
          <nav className={styles["header-nav-sns"]}>
            <ul className={styles["header-nav-sns-list"]}>
              <li className={styles["header-nav-sns-item"]}>
                <a
                  href="https://twitter.com/hayayu1231"
                  className={styles["header-nav-sns-item-link"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15.65 12.72"
                  >
                    <g>
                      <g>
                        <path d="M15.65,1.5A6.36,6.36,0,0,1,13.81,2,3.25,3.25,0,0,0,15.22.23a6.3,6.3,0,0,1-2,.78,3.22,3.22,0,0,0-5.56,2.2,3,3,0,0,0,.09.73A9.11,9.11,0,0,1,1.09.58a3.21,3.21,0,0,0,1,4.29,3.12,3.12,0,0,1-1.45-.4v0A3.2,3.2,0,0,0,3.21,7.66a3.05,3.05,0,0,1-.85.11,3.08,3.08,0,0,1-.61-.06,3.21,3.21,0,0,0,3,2.23,6.39,6.39,0,0,1-4,1.38A4.77,4.77,0,0,1,0,11.27a9,9,0,0,0,4.92,1.45,9.07,9.07,0,0,0,9.14-9.14c0-.14,0-.28,0-.42a6.32,6.32,0,0,0,1.6-1.66" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={styles["header-nav-sns-item"]}>
                <a
                  href="https://www.youtube.com/channel/UCYGw6NczdNmVgBWHQStQgxw"
                  className={styles["header-nav-sns-item-link"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17.2 12.04"
                  >
                    <g>
                      <g>
                        <path d="M16.84,1.88A2.14,2.14,0,0,0,15.32.36,51.31,51.31,0,0,0,8.6,0,51.31,51.31,0,0,0,1.88.36,2.16,2.16,0,0,0,.36,1.88,22.34,22.34,0,0,0,0,6a22.34,22.34,0,0,0,.36,4.14,2.14,2.14,0,0,0,1.52,1.52A51.31,51.31,0,0,0,8.6,12a51.31,51.31,0,0,0,6.72-.36,2.13,2.13,0,0,0,1.52-1.52A22.34,22.34,0,0,0,17.2,6,22.34,22.34,0,0,0,16.84,1.88ZM6.88,8.6V3.44L11.35,6Z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={styles["header-nav-sns-item"]}>
                <a
                  href="https://weibo.com/u/6543918405?refer_flag=1001030103_&amp;is_all=1"
                  className={styles["header-nav-sns-item-link"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17.66 14.3"
                  >
                    <g>
                      <g>
                        <path d="M16.45,1.54A4.7,4.7,0,0,0,12,.1h0a.69.69,0,0,0-.52.8.68.68,0,0,0,.81.52A3.33,3.33,0,0,1,16.15,5.7h0a.66.66,0,0,0,.43.85h0a.67.67,0,0,0,.85-.43h0a4.66,4.66,0,0,0-1-4.57" />
                        <path d="M14.67,3.15a2.26,2.26,0,0,0-2.16-.7.58.58,0,1,0,.21,1.14h0a1.09,1.09,0,0,1,1.06.34A1.11,1.11,0,0,1,14,5h0a.58.58,0,0,0,.38.73.58.58,0,0,0,.73-.37,2.28,2.28,0,0,0-.48-2.23" />
                        <path d="M13.27,6.89c-.25-.08-.42-.13-.29-.46a1.82,1.82,0,0,0,0-1.75c-.57-.82-2.14-.78-3.94,0,0,0-.56.25-.42-.2a2,2,0,0,0-.19-2.06c-1-1-3.57,0-5.8,2.26C1,6.32,0,8.08,0,9.61c0,2.92,3.74,4.69,7.41,4.69,4.8,0,8-2.79,8-5A2.67,2.67,0,0,0,13.27,6.89ZM1.78,10.31c-.19-1.92,2-3.71,5-4s5.45,1,5.64,3-2,3.71-4.95,4S2,12.23,1.78,10.31Z" />
                        <path d="M7.71,7.78A3.2,3.2,0,0,0,4.14,9.34a2.17,2.17,0,0,0,1.39,3.09,3.16,3.16,0,0,0,3.77-1.6A2.19,2.19,0,0,0,7.71,7.78ZM6.65,11a1.11,1.11,0,0,1-1.35.44A.78.78,0,0,1,5,10.25a1.11,1.11,0,0,1,1.32-.44A.77.77,0,0,1,6.65,11Zm.93-1.2a.43.43,0,0,1-.51.19.3.3,0,0,1-.13-.44.43.43,0,0,1,.5-.19A.3.3,0,0,1,7.58,9.78Z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={styles["header-nav-sns-item"]}>
                <a
                  href="https://www.imdb.com/name/nm3349155/"
                  className={styles["header-nav-sns-item-link"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17.2 17.2"
                  >
                    <g>
                      <g>
                        <path d="M13.66,8a.16.16,0,0,0-.16.09,1.94,1.94,0,0,0,0,.45V10a1.45,1.45,0,0,0,0,.45.16.16,0,0,0,.16.1.25.25,0,0,0,.22-.11,1.92,1.92,0,0,0,0-.48V8.57a1.32,1.32,0,0,0,0-.44C13.85,8.06,13.74,8,13.66,8Z" />
                        <path d="M10.26,6.87a.74.74,0,0,0-.3,0v3.46q.29,0,.36-.12a1.93,1.93,0,0,0,.06-.64v-2a2.39,2.39,0,0,0,0-.46A.24.24,0,0,0,10.26,6.87Z" />
                        <path d="M16.07,0H1.16A1.23,1.23,0,0,0,0,1.14V16A1.22,1.22,0,0,0,1.12,17.2h15A1.23,1.23,0,0,0,17.2,16V1.23A1.23,1.23,0,0,0,16.07,0ZM3.44,11.2H2.07V5.92H3.44Zm4.68,0H6.93V7.64L6.45,11.2H5.6L5.1,7.71V11.2H3.9V5.92H5.67c0,.32.11.7.16,1.13L6,8.39l.31-2.47H8.12Zm3.57-1.56a4.44,4.44,0,0,1-.07.94.71.71,0,0,1-.24.38.93.93,0,0,1-.4.19,4.31,4.31,0,0,1-.74,0H8.59V5.92h1A6.33,6.33,0,0,1,10.88,6a1,1,0,0,1,.53.31.84.84,0,0,1,.23.46,6.77,6.77,0,0,1,0,1Zm3.55.35a3.1,3.1,0,0,1-.06.71.71.71,0,0,1-.3.4,1,1,0,0,1-.56.17,1.38,1.38,0,0,1-.49-.1,1.25,1.25,0,0,1-.36-.3l-.09.33H12.15V5.92h1.32V7.64a1.17,1.17,0,0,1,.36-.28,1.38,1.38,0,0,1,.49-.1,1.09,1.09,0,0,1,.47.09.67.67,0,0,1,.31.23.71.71,0,0,1,.12.31c0,.09,0,.3,0,.62Z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={styles["header-nav-sns-item"]}>
                <a
                  href="https://www.instagram.com/yukihayashi1231/?hl=ja"
                  className={styles["header-nav-sns-item-link"]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.94 14.94"
                  >
                    <g>
                      <g>
                        <path d="M14.94,10.57V4.37A4.37,4.37,0,0,0,10.57,0H4.37A4.37,4.37,0,0,0,0,4.37v6.2a4.37,4.37,0,0,0,4.37,4.37h6.2A4.37,4.37,0,0,0,14.94,10.57ZM10.57,1.4a3,3,0,0,1,3,3v6.2a3,3,0,0,1-3,3H4.37a3,3,0,0,1-3-3V4.37a3,3,0,0,1,3-3Zm1.82,2.09a.93.93,0,1,1-.93-.93A.93.93,0,0,1,12.39,3.49Zm-1.06,4a3.86,3.86,0,1,0-3.86,3.86A3.86,3.86,0,0,0,11.33,7.47Zm-1.39,0A2.47,2.47,0,1,1,7.47,5,2.48,2.48,0,0,1,9.94,7.47Z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>

          <div className={styles["header-lang"]}>
            <ul className={styles["header-lang-list"]}>
              <li
                className={clsx(styles["header-lang-item"], {
                  [styles["header-lang-item-active"]]: defaultLng === "ja-JP",
                  [styles["header-lang-item-view"]]:
                    viewLang && defaultLng !== "ja-JP",
                })}
                onClick={() => {
                  langClick(defaultLng === "ja-JP", "ja-JP");
                }}
              >
                {t("common_lang_jp")}
              </li>
              <li
                className={clsx(styles["header-lang-item"], {
                  [styles["header-lang-item-active"]]: defaultLng === "en-US",
                  [styles["header-lang-item-view"]]:
                    viewLang && defaultLng !== "en-US",
                })}
                onClick={() => {
                  langClick(defaultLng === "en-US", "en-US");
                }}
              >
                {t("common_lang_en")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          setViewMenu(!viewMenu);
        }}
        className={styles["header-menu-button"]}
      >
        <span className={styles["header-menu-button-lines"]}>
          <span
            className={clsx(styles["header-menu-button-line-top"], {
              [styles["header-menu-button-line-top-active"]]: viewMenu,
            })}
          ></span>
          <span
            className={clsx(styles["header-menu-button-line-center"], {
              [styles["header-menu-button-line-center-active"]]: viewMenu,
            })}
          ></span>
          <span
            className={clsx(styles["header-menu-button-line-bottom"], {
              [styles["header-menu-button-line-bottom-active"]]: viewMenu,
            })}
          ></span>
        </span>
      </button>
    </header>
  );
};
