import React, { useState, useEffect } from "react";
import { rest, Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { getPrefix } from "@karpeleslab/klbfw";
import { useHistory } from "react-router-dom";

// component
import HelmetComponents from "components/Helmet/Helmet";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import { error } from "components/Toast/Toast";
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";

// utile

// style
import styles from "./PasswordReset.module.scss";

// img

// const

const PasswordReset = () => {
  const { t } = useTranslation();
  const { login } = useParams();
  const [loading, setloading] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConf, setNewPasswordConf] = useState("");
  const [comp, setComp] = useState(false);
  const history = useHistory();

  const resetPost = (e) => {
    e.preventDefault();

    setloading(true);
    rest("User:recover_password", "POST", {
      login: login,
      key: Get("k"),
      p1: newPassword,
      p2: newPasswordConf,
    })
      .then(() => {
        setloading(false);
        setComp(true);
      })
      .catch((err) => {
        setloading(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (
      !newPassword.trim() ||
      !newPasswordConf.trim() ||
      newPasswordConf.length < 6 ||
      newPassword.length < 6
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [newPassword, newPasswordConf]);

  return (
    <>
      <HelmetComponents>
        <title>
          {t("login_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("login_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <h2 className="c-ttl-2">
            <span className="c-ttl-2__main">{t("fotgot_title")}</span>
          </h2>
          <div className={styles["content-container"]}>
            <div className={styles["forgot-container"]}>
              {comp && (
                <>
                  <p className={styles["forgot-text"]}>
                    {t("reset_text_comp")}
                  </p>
                  <div className={styles["forgot-btn-group"]}>
                    <Button
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      {t("common_login")}
                    </Button>
                  </div>
                </>
              )}
              {!comp && (
                <>
                  <p
                    className={styles["forgot-text"]}
                    dangerouslySetInnerHTML={{ __html: t("reset_text") }}
                  ></p>
                  <form onSubmit={(e) => resetPost(e)}>
                    {newPassword !== newPasswordConf && (
                      <p className={styles["forgot-error"]}>
                        {t("reset_pass")}
                      </p>
                    )}
                    {newPasswordConf.length > 0 &&
                      newPassword.length > 0 &&
                      newPasswordConf.length < 6 &&
                      newPassword.length < 6 && (
                        <p className={styles["forgot-error"]}>
                          {t("reset_error_length")}
                        </p>
                      )}
                    <InputForm
                      type="password"
                      value={newPassword}
                      name="user-password"
                      label={t("reset_label_new_password")}
                      placeholder={t("input_field_placeholder", {
                        text: t("reset_label_new_password"),
                      })}
                      handleChange={setNewPassword}
                      disabled={loading}
                    />
                    <InputForm
                      type="password"
                      value={newPasswordConf}
                      name="user-password-conf"
                      label={t("reset_label_new_password_confirm")}
                      placeholder={t("input_field_placeholder", {
                        text: t("reset_label_new_password_confirm"),
                      })}
                      handleChange={setNewPasswordConf}
                      disabled={loading}
                    />
                    <div className={styles["forgot-btn-group"]}>
                      <Button
                        btnTypes="submit"
                        disabled={
                          !formEnabled ||
                          loading ||
                          newPassword !== newPasswordConf
                        }
                      >
                        {t("fotgot_submit")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingOverLay />}
    </>
  );
};

export default PasswordReset;
