import React from "react";
import { Link } from "react-router-dom";

// material ui
import Button from "@material-ui/core/Button";
import clsx from "clsx";

// styles

// imgx

const Btn = ({
  btnTypes = "button",
  children = "default",
  onClick = null,
  disabled = false,
  className = null,
  to = "/",
}) => {
  const btn = () => {
    if (btnTypes === "submit") {
      return (
        <Button
          className={clsx("c-btn-3__item mgb-pc--20 mgb-sp--20", {
            [className]: className !== null,
          })}
          disabled={disabled}
          type="submit"
        >
          {children}
        </Button>
      );
    } else if (btnTypes === "link") {
      return (
        <Button
          component={Link}
          className={clsx("c-btn-3__item mgb-pc--20 mgb-sp--20", {
            [className]: className !== null,
          })}
          to={to}
        >
          {children}
        </Button>
      );
    } else {
      return (
        <Button
          className={clsx("c-btn-3__item mgb-pc--20 mgb-sp--20", {
            [className]: className !== null,
          })}
          disabled={disabled}
          onClick={() => {
            onClick();
          }}
        >
          {children}
        </Button>
      );
    }
  };

  return <>{btn()}</>;
};

export default Btn;
