import React from "react";
import { getLocale } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Link } from "react-router-dom";

// component
import SlideUp from "components/SlideUp/SlideUp";
import FadeUp from "components/FadeUp/FadeUp";
import ListDisplay from "components/Cms/ListDisplay";

// utils

// style

// img

const News = () => {
  const { t } = useTranslation();
  const defaultLng = getLocale();

  const [news] = useRest(
    "Content/Cms/@news:search",
    {
      results_per_page: 5,
      sort: "weight:desc,published:desc",
      page_no: 1,
      audience: "web",
      query: {
        tag: ["NEWS"],
      },
    },
    true
  );

  return (
    <>
      <h2 id="new" className="c-ttl-2">
        <span className="c-ttl-2__main">
          <SlideUp>
            <div
              dangerouslySetInnerHTML={{ __html: t("common_news_animation") }}
            />
          </SlideUp>
        </span>

        {defaultLng === "ja-JP" && (
          <FadeUp>
            <span className="c-ttl-2__sub js-language-jp js-fadeup is-fadeup">
              {t("news_title_sub")}
            </span>
          </FadeUp>
        )}
      </h2>

      <section className="c-section-5">
        <h3 className="c-ttl-3">{t("news_title_information")}</h3>

        <div className="c-section-5__content">
          <ListDisplay news={news} />
          {news !== null && news !== false && news?.data.data.length !== 0 && (
            <div className="c-btn-4">
              <Link to="/info" className="c-btn-4__link">
                {t("common_view_more")}
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default News;
