import React, { useState } from "react";

// component
import StripeWrapper from "components/Payment/StripeWrapper";

const EditBilling = ({ billing, setEdit, billingRefresh }) => {
  const [load, setLoad] = useState(false);
  return (
    <div>
      <StripeWrapper
        billing={billing}
        setEdit={setEdit}
        type="update"
        billingRefresh={billingRefresh}
        load={load}
        setLoad={setLoad}
      />
    </div>
  );
};

export default EditBilling;
