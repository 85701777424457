import React from "react";

// material ui
import {
  FormControl,
  Input,
  FormHelperText,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

// component

// utils

// icon
import { Send } from "@material-ui/icons";

// style

// img

const CommentInput = ({
  name = null,
  label = "default",
  handleChange = null,
  value = "",
  placeholder = "",
  helperText = "",
  disabled = false,
  type = "text",
  required = false,
  error = false,
  postMessage = null,
}) => {
  return (
    <FormControl required={required} fullWidth error={error}>
      <InputLabel
        shrink={true}
        disableAnimation={true}
        htmlFor={`${name}-helper`}
      >
        {label}
      </InputLabel>
      <Input
        id={`${name}-helper`}
        value={value}
        onChange={(e) => {
          handleChange(e);
        }}
        name={name}
        aria-describedby={`${name}-helper-text`}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={postMessage}
              disabled={value.length === 0 || disabled}
            >
              <Send />
            </IconButton>
          </InputAdornment>
        }
      />
      {helperText.length !== 0 && (
        <FormHelperText id={`${name}-helper-text`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CommentInput;
