import React from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// material ui

// component

// styles
import styles from "../MyPage.module.scss";

const Tickets = ({ tickets }) => {
  const { t } = useTranslation();

  return (
    <div className={styles["tickets-wrapper"]}>
      <h3 className="c-ttl-5">{t("mypage_has_tickets")}</h3>
      {tickets.data.length === 0 && (
        <>
          <p
            style={{ textAlign: "center", marginTop: "20px" }}
            className={styles["tickets-qr-text"]}
          >
            {t("mypage_ticket_nothing")}
          </p>
        </>
      )}
      {tickets.data.length > 0 && (
        <>
          <div className={styles["tickets-wrapper-inner"]}>
            {tickets.data.map((item, index) => {
              return (
                <div className={styles["tickets-item"]} key={index}>
                  {item.Planner_Event_Seat.Type !== "stream" && (
                    <>
                      <div className={styles["tickets-qr"]}>
                        <QRCode
                          value={item.Ticket_Url}
                          includeMargin={true}
                          renderAs="svg"
                          size={200}
                        />
                      </div>
                      <p className={styles["tickets-qr-text"]}>
                        {t("mypage_tickets_qr_text")}
                      </p>
                    </>
                  )}
                  <ul className={styles["tickets-info"]}>
                    <li>{item.Planner_Event.Name}</li>
                    <li>{item.Planner_Event_Seat.Seat_Name_Display}</li>
                  </ul>
                  <dl className={styles["tickets-info"]}>
                    <div className={styles["tickets-info-item"]}>
                      <dt>{t("mypage_tickets_event_date")}</dt>
                      <dd>
                        {moment(
                          parseInt(item.Planner_Event.Event_Date.unixms)
                        ).format("YYYY/MM/DD HH:mm")}
                      </dd>
                    </div>
                    <div className={styles["tickets-info-item"]}>
                      <dt>{t("mypage_tickets_event_seat_num")}</dt>
                      <dd>{item.Serial}</dd>
                    </div>
                    {item.Allocated_Seat !== "" &&
                      item.Allocated_Seat !== null && (
                        <div className={styles["tickets-info-item"]}>
                          <dt>{t("mypage_tickets_event_allocated_Seat")}</dt>
                          <dd>{item.Allocated_Seat}</dd>
                        </div>
                      )}
                  </dl>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Tickets;
