import React from "react";

// material ui

// component
import History from "./section/History";
import User from "./section/User";
import Profile from "./section/Profile";
import Password from "./section/Password";
import Billing from "./section/Billing";
import MemberShip from "./section/MemberShip";
import UserLocation from "./section/UserLocation";
import Top from "./section/Top";

// styles
import styles from "./MyPage.module.scss";

const MyPage = ({ type }) => {
  const buildSection = () => {
    switch (type) {
      case "history":
        return <History />;
      case "user":
        return <User />;
      case "profile":
        return <Profile />;
      case "password":
        return <Password />;
      case "billing":
        return <Billing />;
      case "membership":
        return <MemberShip />;
      case "location":
        return <UserLocation />;
      default:
        return <Top />;
    }
  };

  return (
    <>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>{buildSection()}</div>
      </div>
    </>
  );
};

export default MyPage;
