import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import AvatarEditor from "react-avatar-editor";

// material ui
import { Slider, Avatar as AvatarElement } from "@material-ui/core";

// component
import Button from "components/Btns/Btn";

// styles
import styles from "./Avatar.module.scss";

// img
import AvatarImg from "assets/img/img-avatar-default.jpg";
import clsx from "clsx";

const Avatar = ({
  field,
  required = false,
  setBlob = null,
  userImg = null,
}) => {
  const { t } = useTranslation();
  const refAvatarEditor = useRef(null);
  const refFileInput = useRef(null);
  const [scale, setScale] = useState(1);
  const [avatarImg, setAvatarImg] = useState(null);
  const [edit, setEdit] = useState(false);
  const [imgPath, setImgPath] = useState(userImg);

  const handleChange = (event, newValue) => {
    setScale(newValue);
  };

  const updateAvatar = () => {
    refAvatarEditor.current.getImageScaledToCanvas().toBlob((blob) => {
      blob["name"] = "avatar.png";
      let imgArray = {};
      imgArray[field.name] = {
        file: blob,
        param: field.param,
        target: field.target,
      };
      setBlob(imgArray);
      setAvatarImg(blob);
      setEdit(false);
      refFileInput.current.value = "";
    });
  };

  const cancelAvatar = () => {
    setImgPath(null);
    setEdit(false);
    setBlob(null);
    refFileInput.current.value = "";
  };

  const handleNewImage = (e) => {
    setImgPath(e.target.files[0]);
    setEdit(true);
  };

  return (
    <>
      <div className={styles["avatar-container"]}>
        <div
          className={clsx(styles["avatar-wrapper"], {
            [styles["avatar-wrapper-active"]]: edit,
          })}
        >
          <AvatarEditor
            ref={refAvatarEditor}
            className={styles["avatar-editor"]}
            image={imgPath}
            width={180}
            height={180}
            border={0}
            color={[0, 0, 0, 1]} // RGBA
            scale={scale}
            rotate={0}
          />
          <Slider
            className={styles["avatar-slider"]}
            value={scale}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
            min={1}
            max={2}
            step={0.01}
            defaultValue={1}
          />
          <div className={styles["avatar-btns"]}>
            <Button
              onClick={() => {
                cancelAvatar();
              }}
              iconPosition="noting"
            >
              {t("common_cancel")}
            </Button>
            <Button
              onClick={() => {
                updateAvatar();
              }}
              iconPosition="noting"
            >
              {t("common_save")}
            </Button>
          </div>
        </div>
        <div
          className={clsx(styles["avatar-wrapper"], {
            [styles["avatar-wrapper-active"]]: !edit,
          })}
        >
          <AvatarElement
            className={styles["avatar-img"]}
            src={
              avatarImg === null
                ? AvatarImg
                : window.URL.createObjectURL(avatarImg)
            }
          />
          <label className={styles["avatar-btn"]}>
            {t("common_upload")}
            <input
              name={field.name}
              ref={refFileInput}
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/svg"
              onChange={(e) => {
                handleNewImage(e);
              }}
              required={required}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default Avatar;
