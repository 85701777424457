import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPrefix } from "@karpeleslab/klbfw";

// material ui

// component
import HelmetComponents from "components/Helmet/Helmet";
import OrderList from "components/List/OrderList";
import StripeWrapper from "components/Payment/StripeWrapper";
import Free from "components/Payment/Free";

// style
import styles from "./Order.module.scss";

const Order = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const location = useLocation();
  const [orderProcess, setOrderProcess] = useRest(`Order/${slug}:process`);
  const orderProcessRefresh = useRestRefresh(`Order/${slug}:process`);
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const [orderComp, setOrderComp] = useState(false);

  const [load, setLoad] = useState(false);

  return (
    <>
      <HelmetComponents>
        <title>{`${t("common_order")} - ${t("ccommon_meta_title")}`}</title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}${location.pathname}`}
        />
        <meta
          property="og:title"
          content={`${t("common_order")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}${
            location.pathname
          }`}
        />
      </HelmetComponents>
      <div className="c-block">
        <div className={`${styles["content-wrapper"]}`}>
          <div className={styles["content-container"]}>
            <h3 className="c-ttl-5">{t("common_order")}</h3>
            {orderComp && (
              <p className={styles["order-comp"]}>
                {t("subscribe_content_comp")}
              </p>
            )}
            {orderProcess !== null && (
              <>
                <OrderList order={orderProcess} />
              </>
            )}
          </div>
        </div>
        {orderProcess !== null &&
          orderProcess.data.order.Status !== "completed" && (
            <div className={`${styles["content-wrapper"]}`}>
              <div className={styles["content-container"]}>
                {orderProcess.data.methods.Stripe !== void 0 && (
                  <>
                    <StripeWrapper
                      type="order"
                      setLoad={setLoad}
                      load={load}
                      settings={orderProcess.data.methods.Stripe.fields}
                      orderProcess={orderProcess}
                      cartRefresh={cartRefresh}
                      orderProcessRefresh={orderProcessRefresh}
                      setOrderComp={setOrderComp}
                      setOrderProcess={setOrderProcess}
                    />
                  </>
                )}
                {orderProcess.data.methods.Free !== void 0 && (
                  <Free
                    setLoad={setLoad}
                    load={load}
                    orderProcess={orderProcess}
                    cartRefresh={cartRefresh}
                    orderProcessRefresh={orderProcessRefresh}
                    setOrderComp={setOrderComp}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default Order;
