import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { getPrefix, rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// component
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";
import Password from "components/Password/Password";
import Loading from "components/Loading/Loading";
import Toast, { error, success } from "components/Toast/Toast";
import HelmetComponents from "components/Helmet/Helmet";

// styles
import styles from "../MyPage.module.scss";

const User = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const [email, setEmail] = useState(user?.data.Email);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const submitForm = (password) => {
    setLoad(true);

    const data = {
      email: email,
      current_password: password,
    };

    rest("User/@:setEmail", "POST", data)
      .then(() => {
        setOpen(false);
        setLoad(false);
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            userRefresh();
          },
        });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (user === null) {
    return <Loading />;
  }

  return (
    <>
      <HelmetComponents>
        <title>
          {t("maypage_tab_user")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("maypage_tab_user")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div>
        <p className={styles["mypage-back"]}>
          <Link className={styles["mypage-back-link"]} to="/mypage">
            {t("mypage_back")}
          </Link>
        </p>
        <h3 className="c-ttl-5" style={{ marginBottom: "20px" }}>
          {t("maypage_tab_user")}
        </h3>
        <InputForm
          type="email"
          value={email}
          name="user-email"
          label={t("mypage_label_user")}
          placeholder={t("input_field_placeholder", {
            text: t("mypage_label_user"),
          })}
          handleChange={setEmail}
          required={true}
          disabled={load}
        />
        <div className={`mgt-pc--30 mgt-sp--40" ${styles["mypage-btns"]}`}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            iconPosition="noting"
            disabled={user.data.Email === email}
          >
            {t("common_save")}
          </Button>
        </div>
        <Password
          disabled={load}
          setOpen={setOpen}
          open={open}
          submit={submitForm}
        />
        <Toast duration={5000} />
      </div>
    </>
  );
};

export default User;
