import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

// component

// style
// import styles from "../Index.module.scss";

// img

const Tabs = ({ currentTab, setCurrentTab }) => {
  const { t } = useTranslation();

  return (
    <ul className="c-list-button">
      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "All",
          })}
          onClick={() => {
            setCurrentTab("All");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_all")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Animation",
          })}
          onClick={() => {
            setCurrentTab("Animation");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_animation")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Drama",
          })}
          onClick={() => {
            setCurrentTab("Drama");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_drama")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Game",
          })}
          onClick={() => {
            setCurrentTab("Game");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_game")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Movie",
          })}
          onClick={() => {
            setCurrentTab("Movie");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_movie")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Culture",
          })}
          onClick={() => {
            setCurrentTab("Culture");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_culture")}</span>
          </span>
        </div>
      </li>

      <li className="c-list-button__item">
        <div
          className={clsx("c-btn-1", {
            ["is-active"]: currentTab === "Other",
          })}
          onClick={() => {
            setCurrentTab("Other");
          }}
        >
          <span className="c-btn-1__border">
            <span className="c-btn-1__txt">{t("common_tag_other")}</span>
          </span>
        </div>
      </li>
    </ul>
  );
};

export default Tabs;
