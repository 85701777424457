import React from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Redirect, useLocation } from "react-router-dom";

const LoginRequired = ({ children }) => {
  const location = useLocation();
  const [user] = useRest("User:get");

  if (user === null) return false;

  if (user.data !== null) {
    return <>{children}</>;
  }

  const returnTo = encodeURIComponent(
    location.pathname + location.search + location.hash
  );

  return (
    <Redirect from={location.pathname} to={`/login?return_to=${returnTo}`} />
  );
};

export default LoginRequired;
