import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocale, rest } from "@karpeleslab/klbfw";

// component
import SlideUp from "components/SlideUp/SlideUp";
import FadeUp from "components/FadeUp/FadeUp";
import Tabs from "components/Works/Tab";
import Thumbnails from "components/Works/Thumbnails";
import Loading from "components/Loading/Loading";
import { error } from "components/Toast/Toast";

// style
// import styles from "../Index.module.scss";

// img

const Works = () => {
  const { t } = useTranslation();
  const IMG_VARIATION = "format=jpeg&strip&scale_crop=300x300";
  const [currentTab, setCurrentTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTags, setFilterTags] = useState("");
  const [works, setWorks] = useState([]);
  const [paging, setPaging] = useState();
  const defaultLng = getLocale();

  useEffect(() => {
    let params = {
      Music_Label_Artist__: "@artist",
      results_per_page: 16,
      page_no: currentPage,
      image_variation: IMG_VARIATION,
    };

    if (filterTags !== "") {
      params.Tag = filterTags;
    }

    rest("Music/Label/Release", "GET", params).then((data) => {
      setWorks([...works, ...data.data]);
      setPaging(data.paging);
    });
  }, [currentPage]);

  useEffect(() => {
    let params = {
      Music_Label_Artist__: "@artist",
      results_per_page: 16,
      page_no: currentPage,
      image_variation: IMG_VARIATION,
    };

    if (filterTags !== "") {
      params.Tag = filterTags;
    }

    rest("Music/Label/Release", "GET", params)
      .then((data) => {
        setWorks(data.data);
        setPaging(data.paging);
      })
      .catch((err) => {
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  }, [filterTags]);

  useEffect(() => {
    setWorks(null);
    setCurrentPage(1);
    if (currentTab !== "All") {
      setFilterTags(currentTab);
    } else {
      setFilterTags("");
    }
  }, [currentTab]);

  return (
    <>
      <section className="c-section c-section--none">
        <div className="c-section__container">
          <div className="c-box-lineup c-box-lineup--low">
            <h2 id="works" className="c-ttl-2">
              <span className="c-ttl-2__main">
                <SlideUp>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("common_works_animation"),
                    }}
                  />
                </SlideUp>
              </span>
              {defaultLng === "ja-JP" && (
                <FadeUp>
                  <span className="c-ttl-2__sub">
                    {t("woorks_list_top_text")}
                  </span>
                </FadeUp>
              )}
            </h2>
            <FadeUp>
              <div className="c-box-lineup__content">
                <div className="c-list-button-inner">
                  <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
                </div>
                {works === null && works !== false && <Loading />}
                {works !== null && works !== false && (
                  <Thumbnails
                    paging={paging}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    works={works}
                  />
                )}
              </div>
            </FadeUp>
          </div>
        </div>
      </section>
    </>
  );
};

export default Works;
