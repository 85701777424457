import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import moment from "moment";

// component
import Like from "./Like";
import CommentIcon from "./CommentIcon";
import CommentInput from "./CommentInput";
import LoadingElements from "components/Loading/LoadingElements";

// utils

// icon

// style
import styles from "../Cms.module.scss";

// img

const Comment = ({ cmsEntry = null }) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [messageInMessage, setMessageInMessage] = useState("");
  const [comments, setComments] = useState(null);
  const [childrenComments, setChildrenComments] = useState(null);
  const [childrenLoad, setChildrenLoad] = useState(false);
  const [currentSocialKey, setCurrentSocialKey] = useState(null);
  const [send, setSend] = useState(false);

  useEffect(() => {
    if (cmsEntry !== null) {
      rest("Social/Comment", "GET", {
        key: cmsEntry.Comments.Key,
        results_per_page: 100,
      }).then((data) => {
        setComments(data);
      });
    }
  }, [cmsEntry]);

  const refreshComments = () => {
    rest("Social/Comment", "GET", {
      key: cmsEntry.Comments.Key,
      results_per_page: 100,
    }).then((data) => {
      setComments(data);
      setOpenNum(null);
    });
  };

  const postMessage = () => {
    setSend(true);
    rest(`Content/Cms/Entry/${cmsEntry.Content_Cms_Entry__}:comment`, "POST", {
      message: message,
    }).then(() => {
      setSend(false);
      setMessage("");
      refreshComments();
    });
  };

  const postComentInComment = (id) => {
    setSend(true);
    rest(`Social/Comment/${id}:comment`, "POST", {
      message: messageInMessage,
    }).then(() => {
      setSend(false);
      setMessageInMessage("");
      setChildrenLoad(true);
      requestChildrenComment(currentSocialKey);
    });
  };

  const requestChildrenComment = (key) => {
    setChildrenComments(null);
    setCurrentSocialKey(key);
    rest("Social/Comment", "GET", {
      key: key,
      results_per_page: 100,
    }).then((data) => {
      setChildrenLoad(false);
      setChildrenComments(data);
    });
  };

  // comemnts in Input
  const [openNum, setOpenNum] = useState(null);

  return (
    <>
      {comments !== null && comments?.data.length !== 0 && (
        <div className={styles["comments-list-wrapper"]}>
          {comments.data.map((val, index) => {
            return (
              <div className={styles["comments-list-item"]} key={index}>
                <div className={styles["comments-list-item-inner"]}>
                  <div className={styles["comments-list-item-right"]}>
                    <dl>
                      <dt>{val.Profile.Display_Name}</dt>
                      <dd>
                        {moment(parseInt(val.Date.unixms)).format(
                          "MMM DD, YYYY  HH:mm:ss"
                        )}
                      </dd>
                    </dl>
                    <p>{val.Message}</p>
                  </div>
                  <div>
                    <Like
                      type="comment"
                      targetId={val.Social_Comment__}
                      likes={val.Likes}
                    />
                    <CommentIcon
                      comments={val.Comments}
                      handleDisplay={() => {
                        setChildrenComments(null);
                        setChildrenLoad(true);
                        requestChildrenComment(val.Comments.Key);

                        if (openNum === index && openNum !== null) {
                          setOpenNum(null);
                        } else {
                          setOpenNum(index);
                        }
                      }}
                    />
                  </div>
                </div>
                {index === openNum && (
                  <div className={styles["comments-chirdren-wrapper"]}>
                    {childrenLoad === true && (
                      <div className={styles["comment-load"]}>
                        <LoadingElements />
                      </div>
                    )}
                    {childrenComments !== null && childrenLoad === false && (
                      <>
                        {childrenComments.data.map((val, indexNum) => {
                          return (
                            <div
                              className={styles["comments-chirdren-list-item"]}
                              key={indexNum}
                            >
                              <div
                                className={
                                  styles["comments-chirdren-list-item-inner"]
                                }
                              >
                                <div
                                  className={
                                    styles["comments-chirdren-list-item-right"]
                                  }
                                >
                                  <dl>
                                    <dt>{val.Profile.Display_Name}</dt>
                                    <dd>
                                      {moment(parseInt(val.Date.unixms)).format(
                                        "MMM DD, YYYY  HH:mm:ss"
                                      )}
                                    </dd>
                                  </dl>
                                  <p>{val.Message}</p>
                                </div>
                                <div>
                                  <Like
                                    type="comment"
                                    targetId={val.Social_Comment__}
                                    likes={val.Likes}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div className={styles["comments-input-children"]}>
                      <CommentInput
                        value={messageInMessage}
                        name="messagInMessage"
                        label={t("comments_children_input_label")}
                        placeholder={t("input_field_placeholder", {
                          text: t("comments_children_input_label"),
                        })}
                        handleChange={(e) => {
                          setMessageInMessage(e.target.value);
                        }}
                        postMessage={() => {
                          postComentInComment(val.Social_Comment__);
                        }}
                        disabled={send}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      <div className={styles["comments-input"]}>
        <CommentInput
          value={message}
          name="message"
          label={t("comments_input_label")}
          placeholder={t("input_field_placeholder", {
            text: t("comments_input_label"),
          })}
          handleChange={(e) => {
            setMessage(e.target.value);
          }}
          postMessage={() => {
            postMessage();
          }}
          disabled={send}
        />
      </div>
    </>
  );
};

export default Comment;
