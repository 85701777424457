import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";

// utile
import { buildDate } from "utils/BuildDate";

// style
import styles from "./List.module.scss";

// img
import LinkIcon from "assets/img/icon-link.svg";
import LinkIconHover from "assets/img/icon-link-hover.svg";

const EventList = ({ vals = null, path = "event" }) => {
  const { t } = useTranslation();

  return (
    <ul className="c-list">
      {vals === null && (
        <p className={styles["list-news__notfound"]}>{t("error_not_found")}</p>
      )}
      {vals !== null &&
        vals.length !== 0 &&
        vals.map((val, index) => {
          const test = _.find(val.Tags, (obj) => {
            return obj.Name === "test";
          });

          if (test === void 0) {
            return (
              <li key={index} className="c-list__item">
                <Link
                  className="c-list__link"
                  to={`/${path}/${val.Content_Cms_Entry_Data.Slug}`}
                >
                  <div className="c-list__inner">
                    <span className="c-list__date">
                      {buildDate(
                        parseInt(
                          val.Planner_Event__ === null
                            ? val.Date.unixms
                            : val.Planner_Event.Event_Date.unixms
                        ),
                        "MMM DD, YYYY"
                      )}
                    </span>
                    <span className="c-list__ttl">
                      {val.Content_Cms_Entry_Data.Title}
                    </span>
                  </div>
                  <span
                    className="c-list__link-icon"
                    style={{
                      backgroundImage: `url(${LinkIcon})`,
                    }}
                  ></span>
                  <span
                    className="c-list__link-icon c-list__link-icon-hover"
                    style={{
                      backgroundImage: `url(${LinkIconHover})`,
                    }}
                  ></span>
                </Link>
              </li>
            );
          }
        })}
      {vals !== null && vals.length === 0 && (
        <p className={styles["list-news__notfound"]}>{t("error_not_found")}</p>
      )}
    </ul>
  );
};

export default EventList;
