import React, { useState } from "react";
import { rest } from "@karpeleslab/klbfw";

// component

// utils

// icon
import { FavoriteBorder, Favorite } from "@material-ui/icons";

// style
import styles from "../Cms.module.scss";

// img

const Like = ({
  targetId = null,
  likes = null,
  className = null,
  type = null,
}) => {
  const [likeNum, setLikeNum] = useState(likes === null ? 0 : likes.Like_Count);
  const [liked, setLiked] = useState(likes === null ? false : likes.Liked);

  const typeCheck = () => {
    switch (type) {
      case "comment":
        return `Social/Comment/${targetId}`;
      default:
        return `Content/Cms/Entry/${targetId}`;
    }
  };

  const unLike = (event) => {
    event.preventDefault();
    rest(`${typeCheck()}:unlike`, "POST").then((data) => {
      setLikeNum(data.data.Like_Count);
      setLiked(data.data.Liked);
    });
  };

  const like = (event) => {
    event.preventDefault();
    rest(`${typeCheck()}:like`, "POST").then((data) => {
      setLikeNum(data.data.Like_Count);
      setLiked(data.data.Liked);
    });
  };

  return (
    <>
      {likeNum !== null && liked !== null && (
        <dl
          className={`${styles["like"]} ${className !== null ? className : ""}`}
        >
          {liked === false && (
            <dt
              onClick={(event) => {
                like(event);
              }}
              className={styles["like-icon"]}
            >
              <FavoriteBorder />
            </dt>
          )}
          {liked === true && (
            <dt
              onClick={(event) => {
                unLike(event);
              }}
              className={styles["like-icon-active"]}
            >
              <Favorite />
            </dt>
          )}
          <dd>{likeNum}</dd>
        </dl>
      )}
    </>
  );
};

export default Like;
