import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import { error } from "components/Toast/Toast";

// component
import Loading from "components/Loading/Loading";
import HelmetComponents from "components/Helmet/Helmet";
import BillingList from "./Billing/BillingList";
import BillingCreate from "./Billing/BillingCreate";
import Button from "components/Btns/Btn";

// styles
import styles from "../MyPage.module.scss";

const Billing = () => {
  const { t } = useTranslation();
  const { pageNum } = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );
  const [load, setLoad] = useState(false);

  const [billing] = useRest(
    "User/@/Billing",
    {
      page_no: currentPage,
      results_per_page: 10,
      sort: { Created: "DESC" },
      _expand: "/User_Location",
    },
    true
  );

  const billingRefresh = () => {
    window.scrollTo(0, 0);
    window.location.reload();
  };

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  const [addModal, setAddModal] = useState(false);

  // membership

  const [membership] = useRest("Membership/@fanclub/User/@", {}, true);
  const [membershipBilling, setMembershipBilling] = useState(null);

  useEffect(() => {
    if (membership === null || membership === false) return;
    setMembershipBilling(membership.data.User_Billing__);
  }, [membership]);

  const changeMembershipBilling = (billingId) => {
    setLoad(true);

    rest(`Membership/@fanclub/User/@:setBilling`, "POST", {
      User_Billing__: billingId,
    })
      .then(() => {
        setLoad(false);
        billingRefresh();
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const deleteBilling = (billingId) => {
    setLoad(true);

    rest(`User/Billing/${billingId}`, "DELETE", {})
      .then(() => {
        setLoad(false);
        billingRefresh();
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (billing === null) {
    return <Loading />;
  }

  return (
    <>
      <HelmetComponents>
        <title>
          {t("maypage_tab_billing")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("maypage_tab_billing")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div>
        <p className={styles["mypage-back"]}>
          <Link className={styles["mypage-back-link"]} to="/mypage">
            {t("mypage_back")}
          </Link>
        </p>
        <h3 className="c-ttl-5" style={{ marginBottom: "20px" }}>
          {t("maypage_tab_billing")}
        </h3>
        <div>
          <Button
            onClick={() => {
              setAddModal(true);
            }}
            iconPosition="noting"
          >
            {t("mypage_billing_add")}
          </Button>
        </div>
        <BillingList
          billingList={billing}
          billingRefresh={billingRefresh}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          membershipBilling={membershipBilling}
          changeMembershipBilling={changeMembershipBilling}
          deleteBilling={deleteBilling}
          setLoad={setLoad}
        />
        {addModal && (
          <BillingCreate
            setAddModal={setAddModal}
            addModal={addModal}
            billingRefresh={billingRefresh}
            load={load}
            setLoad={setLoad}
          />
        )}
      </div>
    </>
  );
};

export default Billing;
