import React, { useState } from "react";

// component

// icon

// style

// img
import HoverOnImg from "assets/img/img-btn-more-on.svg";
import HoverOffImg from "assets/img/img-btn-more-off.svg";

const MoreBtn = ({ currentPage, setCurrentPage }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="c-btn-2">
      <div
        className="c-btn-2__inner"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{
          backgroundImage: !hover
            ? `url(${HoverOffImg})`
            : `url(${HoverOnImg})`,
        }}
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}
      >
        <button className="c-btn-2__item js-loading-trigger link1">MORE</button>
      </div>
    </div>
  );
};

export default MoreBtn;
