import React from "react";

// material ui
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

// style
import styles from "./Form.module.scss";

const SelectInput = ({
  handleChange = null,
  array = [{ label: "null", val: "null" }],
  value = null,
  name = null,
  required = false,
  label = "default",
  disabled = false,
  addClass = null,
}) => {
  return (
    <FormControl
      variant="outlined"
      required={required}
      fullWidth
      className={`${addClass} ${styles["form-control"]}`}
    >
      <InputLabel
        className={styles["form-label"]}
        shrink={true}
        disableAnimation={true}
        htmlFor={`${name}-helper`}
        id="demo-simple-select-outlined-label"
      >
        {label}
      </InputLabel>
      <Select
        className={styles["form-input"]}
        id={`${name}-helper`}
        value={value === null ? "" : value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        displayEmpty
        disabled={disabled}
      >
        {array.map((val, index) => {
          return (
            <MenuItem key={index} value={val.val}>
              {val.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
