import React from "react";

// material ui
import CircularProgress from "@material-ui/core/CircularProgress";

// style
import styles from "./Loading.module.scss";

const LoadingOverLay = () => {
  return (
    <div className={styles["loading-overlay-container"]}>
      <CircularProgress
        className={styles["loading-overlay"]}
        color="secondary"
      />
    </div>
  );
};

export default LoadingOverLay;
