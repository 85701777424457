import React from "react";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import SlideUp from "components/SlideUp/SlideUp";
import FadeUp from "components/FadeUp/FadeUp";

// style
// import styles from "../Index.module.scss";

// img

const Contact = () => {
  const { t } = useTranslation();
  const defaultLng = getLocale();

  return (
    <div className="c-section-2__container">
      <h2 className="c-ttl-2">
        <span className="c-ttl-2__main">
          <SlideUp>
            <div
              dangerouslySetInnerHTML={{
                __html: t("common_contact_animation"),
              }}
            />
          </SlideUp>
        </span>
        {defaultLng === "ja-JP" && (
          <FadeUp>
            <span className="c-ttl-2__sub">{t("contact_top_ja")}</span>
          </FadeUp>
        )}
      </h2>

      <div className="c-section-2__contents">
        <FadeUp>
          <p className="c-section-2__txt">{t("contact_top_text")}</p>
        </FadeUp>
        <FadeUp>
          <div className="c-section-2__contact">
            <p className="c-section-2__contact-txt">{t("contact_top_inc")}</p>
          </div>
        </FadeUp>
        <FadeUp>
          <div className="c-section-2__link">
            <a
              href="https://pro.form-mailer.jp/fms/1bf743f0312580"
              rel="noopener noreferrer"
              target="_blank"
              className="c-section-2__btn"
            >
              {t("common_contact")}
            </a>
          </div>
        </FadeUp>
      </div>
    </div>
  );
};

export default Contact;
