import React from "react";
import NoSsr from "@material-ui/core/NoSsr";

// component
import Nav from "./section/nav";
import News from "./section/News";
import Twitter from "./section/Twitter";
import Profile from "./section/Profile";
import Goods from "./section/Goods";
import Works from "./section/Works";
import Contact from "./section/Contact";

// style

// img
import bgDark from "assets/img/bg-background-dark.jpg";

const Index = () => {
  return (
    <div>
      <Nav />
      <section
        className="c-section c-section--none"
        style={{
          backgroundImage: `URL(${bgDark})`,
        }}
      >
        <div className="c-section__container">
          <div className="c-box-lineup">
            <News />
            <div className="c-box-lineup__inner js-height-outer-1">
              <Goods />
              <Twitter />
            </div>
          </div>
        </div>
      </section>
      <div className="c-section-inner">
        <Profile />
        <NoSsr>
          <Works />
        </NoSsr>
      </div>

      <section
        id="contact"
        className="c-section-2"
        style={{
          backgroundImage: `URL(${bgDark})`,
        }}
      >
        <Contact />
      </section>
    </div>
  );
};

export default Index;
