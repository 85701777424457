import React from "react";

// component

// utils

// icon
import { ChatBubbleOutline } from "@material-ui/icons";

// style
import styles from "../Cms.module.scss";

// img

const CommentIcon = ({ comments = null, handleDisplay = null }) => {
  return (
    <>
      {Comment !== null && (
        <dl className={`${styles["like"]}`}>
          <dt onClick={handleDisplay}>
            <ChatBubbleOutline />
          </dt>
          <dd>{comments.Comment_Count}</dd>
        </dl>
      )}
    </>
  );
};

export default CommentIcon;
