import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams } from "react-router-dom";

// component
import ListDisplay from "components/Cms/ListDisplay";
import Paging from "components/Pagination/Pagination";

// style

// img

const List = () => {
  const location = useLocation();
  const { pageNum } = useParams();

  // page
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [news] = useRest(
    "Content/Cms/@news:search",
    {
      results_per_page: 5,
      sort: "weight:desc,published:desc",
      page_no: currentPage,
      audience: "web",
      query: {
        tag: ["NEWS"],
      },
    },
    true
  );

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  return (
    <>
      <ListDisplay news={news} />
      {news !== null && news !== false && (
        <Paging
          setCurrentPage={setCurrentPage}
          defaultPage={currentPage}
          count={news.paging?.page_max}
        />
      )}
    </>
  );
};

export default List;
