import React, { useState, useEffect } from "react";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { error } from "components/Toast/Toast";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Typography } from "@material-ui/core";

// component
import Loading from "components/Loading/Loading";
import HelmetComponents from "components/Helmet/Helmet";
import Button from "components/Btns/Btn";
import Pagination from "components/Pagination/Pagination";

import BillingInputs from "components/Billing/BillingInputs";

// icon
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

// styles
import styles from "../MyPage.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    maxHeight: "100%",
    overflowY: "auto",
  },
}));

const UserLocation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pageNum } = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [user, refreshUser] = useRest("User/@:get");

  const [userLocations, refreshLocation] = useRest(
    "User/@/Location",
    {
      page_no: currentPage,
      results_per_page: 9,
      sort: { Created: "DESC" },
    },
    true
  );

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  // if (billing === null) {
  //   return <Loading />;
  // }

  // input values
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);
  const [load, setLoad] = useState(false);
  const [currentLocationId, setCurrentLocationId] = useState(null);

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  useEffect(() => {
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
  }, [location.pathname, pageNum]);

  const setValues = (data) => {
    setCountry(data.Country__);
    setFName(data.First_Name);
    setLName(data.Last_Name);
    setZip(data.Zip);
    setProvince(data.Province);
    setCity(data.City);
    setAddress(data.Address);
    setPhone(data.Contact_Phone);
    setCurrentLocationId(data.User_Location__);
  };

  const locationDefaultSet = (id) => {
    setLoad(true);
    window.scrollTo(0, 0);

    rest(`User/@:setDefaultLocation`, "POST", {
      User_Location__: id,
      type: "Billing",
    })
      .then(() => {
        refreshUser();
        refreshLocation();
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const locationDelete = () => {
    setLoad(true);
    window.scrollTo(0, 0);
    rest(`User/Location/${currentLocationId}`, "DELETE")
      .then(() => {
        setLoad(false);
        refreshLocation();
      })
      .catch((err) => {
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const locationUpdate = () => {
    setLoad(true);
    window.scrollTo(0, 0);
    const params = {
      First_Name: fName,
      Last_Name: lName,
      Zip: zip,
      Country__: country,
      Address: address,
      City: city,
      Province: province,
      Contact_Phone: phone,
    };
    rest(`User/@/Location/${currentLocationId}`, "PATCH", params)
      .then(() => {
        refreshLocation();
        setValues({
          Country__: "",
          First_Name: "",
          Last_Name: "",
          Zip: "",
          Province: "",
          City: "",
          Address: "",
          User_Location__: null,
        });
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const locationAdd = () => {
    setLoad(true);
    window.scrollTo(0, 0);
    const params = {
      First_Name: fName,
      Last_Name: lName,
      Zip: zip,
      Country__: country,
      Address: address,
      City: city,
      Province: province,
      Contact_Phone: phone,
    };
    rest(`User/@/Location`, "POST", params)
      .then(() => {
        refreshLocation();
        setValues({
          Country__: "",
          First_Name: "",
          Last_Name: "",
          Zip: "",
          Province: "",
          City: "",
          Address: "",
          User_Location__: null,
        });
        setCountry(geoVal.data.country.iso_code);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (userLocations === null || user === null || load) {
    return <Loading />;
  }

  return (
    <>
      <HelmetComponents>
        <title>
          {t("maypage_tab_location")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("maypage_tab_location")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div>
        <p className={styles["mypage-back"]}>
          <Link className={styles["mypage-back-link"]} to="/mypage">
            {t("mypage_back")}
          </Link>
        </p>
        <h3 className="c-ttl-5" style={{ marginBottom: "20px" }}>
          {t("maypage_tab_location")}
        </h3>
        <div>
          <Button
            onClick={() => {
              setModal(true);
              setModalType("add");
            }}
            iconPosition="noting"
          >
            {t("mypage_location_add")}
          </Button>
        </div>
        {userLocations?.data.length === 0 && (
          <p className={styles["mypage__notfound"]}>
            {t("mypage_location_nothing")}
          </p>
        )}
        <div className={styles["location-wrapper"]}>
          {userLocations.data.map((data, index) => {
            return (
              <div className={styles["location-item"]} key={index}>
                <div className={styles["location-item-text"]}>
                  {data.Display[0]}
                  <br />
                  {data.Display[1]}
                  <br />
                  {data.Display[2]}
                  <br />
                  {data.Display[3] === void 0 ? (
                    ""
                  ) : (
                    <>
                      {data.Display[3]}
                      <br />
                    </>
                  )}
                  {data.Display[4] === void 0 ? (
                    ""
                  ) : (
                    <>
                      {data.Display[4]}
                      <br />
                    </>
                  )}
                  {data.Contact_Phone}
                </div>
                {data.User_Location__ !==
                  user.data.Default_Billing_User_Location__ && (
                  <div className={styles["location-default"]}>
                    {t("mypage_location_btn_default")}
                    <div
                      className={styles["location-default-icon"]}
                      onClick={() => {
                        locationDefaultSet(data.User_Location__);
                      }}
                    >
                      <StarBorderIcon />
                    </div>
                  </div>
                )}
                {data.User_Location__ ===
                  user.data.Default_Billing_User_Location__ && (
                  <div
                    className={`${styles["location-default"]} ${styles["location-default-set"]}`}
                  >
                    {t("mypage_location_btn_default_true")}
                    <div
                      className={`${styles["location-default-icon"]} ${styles["location-default-icon-set"]}`}
                    >
                      <StarIcon />
                    </div>
                  </div>
                )}

                <div className={styles["location-item-btns"]}>
                  <div
                    className={styles["location-item-btns-item"]}
                    onClick={() => {
                      setModal(true);
                      setModalType("edit");
                      setValues(data);
                    }}
                  >
                    {t("mypage_location_btn_edit")}
                  </div>
                  <div
                    className={`${styles["location-item-btns-item"]} ${styles["location-item-btns-item-delete"]}`}
                    onClick={() => {
                      setModal(true);
                      setModalType("delete");
                      setCurrentLocationId(data.User_Location__);
                    }}
                  >
                    {t("mypage_location_btn_delete")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {userLocations !== null && userLocations?.data.length !== 0 && (
          <Pagination
            setCurrentPage={setCurrentPage}
            defaultPage={currentPage}
            count={userLocations?.paging.page_max}
            path="mypage/location"
          />
        )}

        <Modal
          className={classes.modal}
          open={modal}
          onClose={() => {
            setModal(false);
            setValues({
              Country__: "",
              First_Name: "",
              Last_Name: "",
              Zip: "",
              Province: "",
              City: "",
              Address: "",
              User_Location__: null,
            });
            setCountry(geoVal.data.country.iso_code);
          }}
        >
          <div className={classes.paper}>
            {(modalType === "edit" || modalType === "add") && (
              <>
                <Typography
                  className={styles["member-ship-modal-title"]}
                  align="left"
                  variant="h6"
                  component="h2"
                >
                  {modalType === "edit"
                    ? t("mypage_location_edit_title")
                    : t("mypage_location_add")}
                </Typography>
                <BillingInputs
                  fName={fName}
                  setFName={setFName}
                  lName={lName}
                  setLName={setLName}
                  zip={zip}
                  setZip={setZip}
                  province={province}
                  setProvince={setProvince}
                  city={city}
                  setCity={setCity}
                  address={address}
                  setAddress={setAddress}
                  phone={phone}
                  setPhone={setPhone}
                  country={country}
                  setCountry={setCountry}
                  geoVal={geoVal}
                  Countrys={Countrys}
                  formEnabled={formEnabled}
                  setFormEnabled={setFormEnabled}
                  load={load}
                  type="add"
                />
                <div
                  className={styles["member-ship-modal-btns-group"]}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setModal(false);
                      setValues({
                        Country__: "",
                        First_Name: "",
                        Last_Name: "",
                        Zip: "",
                        Province: "",
                        City: "",
                        Address: "",
                        User_Location__: null,
                      });
                      setCountry(geoVal.data.country.iso_code);
                    }}
                    iconPosition="noting"
                  >
                    {t("mypage_modal_btn_close")}
                  </Button>
                  <Button
                    onClick={() => {
                      modalType === "add" ? locationAdd() : locationUpdate();
                      setModal(false);
                    }}
                    iconPosition="noting"
                  >
                    {modalType === "edit"
                      ? t("common_save")
                      : t("mypage_location_add_btn")}
                  </Button>
                </div>
              </>
            )}

            {modalType === "delete" && (
              <>
                <Typography
                  className={styles["member-ship-modal-title"]}
                  align="left"
                  variant="h6"
                  component="h2"
                >
                  {t("mypage_location_delete_title")}
                </Typography>
                <p
                  className={styles["member-ship-modal-text-margin"]}
                  dangerouslySetInnerHTML={{
                    __html: t("mypage_location_delete_text"),
                  }}
                ></p>
                <div className={styles["member-ship-modal-btns-group"]}>
                  <Button
                    onClick={() => {
                      setModal(false);
                    }}
                    iconPosition="noting"
                  >
                    {t("mypage_modal_btn_close")}
                  </Button>
                  <Button
                    onClick={() => {
                      locationDelete();
                      setModal(false);
                    }}
                    className={styles["member-ship-btn-delete"]}
                    iconPosition="noting"
                  >
                    {t("mypage_location_btn_delete")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UserLocation;
