import React from "react";
import { useTranslation } from "react-i18next";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component
import FadeUp from "components/FadeUp/FadeUp";
import TopGoodsList from "components/Goods/TopGoodsList";

// style
// import styles from "../Index.module.scss";

// img

const Goods = () => {
  const { t } = useTranslation();
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const IMG_VARIATION =
    "resolution=200&background_color=white&flatten&strip&format=jpeg&page=*&scale=300x300";
  const [products] = useRest(
    "Catalog/Product:search",
    {
      page_no: 0,
      results_per_page: 3,
      image_variation: IMG_VARIATION,
      sort: "Basic.Priority:desc",
    },
    true
  );

  return (
    <FadeUp>
      <section className="c-section-3 c-section-3--wide">
        <h3 className="c-ttl-3">{t("common_goods")}</h3>

        <div
          className="c-section-3__content js-height-1"
          style={{ height: "610px" }}
        >
          <TopGoodsList cartRefresh={cartRefresh} goods={products} />
        </div>
      </section>
    </FadeUp>
  );
};

export default Goods;
