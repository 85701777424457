import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest, getPrefix } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";

// component
import InputForm from "components/Forms/Input";
import SelectInput from "components/Forms/SelectInput";
import Button from "components/Btns/Btn";
import Loading from "components/Loading/Loading";
import Toast, { error, success } from "components/Toast/Toast";
import HelmetComponents from "components/Helmet/Helmet";

// styles
import styles from "../MyPage.module.scss";

const Profile = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const [userName, setUserName] = useState(user?.data.Profile.Display_Name);
  const [birth, serBirth] = useState(
    user?.data.Profile.Birthdate === "0000-00-00"
      ? moment().format("YYYY-MM-DD")
      : moment(user?.data.Profile.Birthdate).format("YYYY-MM-DD")
  );
  const [gender, setGender] = useState(user?.data.Profile.Gender);
  const [loading, setLoading] = useState(false);

  const genderArray = [
    { label: t("mypage_label_user_gender_select"), val: "" },
    { label: t("mypage_label_user_gender_select_male"), val: "M" },
    { label: t("mypage_label_user_gender_select_female"), val: "F" },
  ];

  const submitForm = () => {
    setLoading(true);

    const data = {
      Gender: gender === "" ? null : gender,
      Display_Name: userName,
      Birthdate:
        birth === ""
          ? moment().format("YYYY-MM-DD")
          : moment(birth).format("YYYY-MM-DD"),
    };

    rest("User/@/Profile", "PATCH", data)
      .then(() => {
        setLoading(false);
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            userRefresh();
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (user === null) {
    return <Loading />;
  }

  return (
    <>
      <HelmetComponents>
        <title>
          {t("maypage_tab_profile")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("maypage_tab_profile")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>
      <div>
        <p className={styles["mypage-back"]}>
          <Link className={styles["mypage-back-link"]} to="/mypage">
            {t("mypage_back")}
          </Link>
        </p>
        <h3 className="c-ttl-5" style={{ marginBottom: "20px" }}>
          {t("maypage_tab_profile")}
        </h3>
        <InputForm
          type="text"
          value={userName}
          name="user-userName"
          label={t("mypage_label_user_name")}
          placeholder={t("input_field_placeholder", {
            text: t("mypage_label_user_name"),
          })}
          handleChange={setUserName}
          disabled={loading}
        />
        <InputForm
          type="date"
          value={birth}
          name="user-birth"
          label={t("mypage_label_user_birth")}
          placeholder={t("input_field_placeholder", {
            text: t("mypage_label_user_birth"),
          })}
          handleChange={serBirth}
          disabled={loading}
          addClass="mgt-pc--30 mgt-sp--40"
        />
        <SelectInput
          name="user-gender"
          label={t("mypage_label_user_gender")}
          value={gender}
          array={genderArray}
          handleChange={setGender}
          disabled={loading}
          addClass="mgt-pc--30 mgt-sp--40"
        />
        <div className={styles["mypage-btns"]}>
          <Button
            onClick={() => {
              submitForm(true);
            }}
            iconPosition="noting"
            disabled={loading}
          >
            {t("common_save")}
          </Button>
        </div>
        <Toast duration={5000} />
      </div>
    </>
  );
};

export default Profile;
