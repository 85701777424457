import React, { useState, useEffect } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPrefix, getLocale, rest } from "@karpeleslab/klbfw";

// material ui

// component
import Loading from "components/Loading/Loading";
import HelmetComponents from "components/Helmet/Helmet";
import Tickets from "./Tickets";

// utils
import { memberIsActive } from "utils/MemberRequired";

// styles
import styles from "../MyPage.module.scss";

// const

const Top = () => {
  const { t } = useTranslation();
  const defaultLng = getLocale();
  const [user] = useRest("User:get");
  const [memberShip, setMemberShip] = useState(null);

  useEffect(() => {
    if (user !== null && user?.data !== null) {
      rest("Membership/@fanclub/User/@")
        .then((data) => {
          setMemberShip(memberIsActive(data));
        })
        .catch(() => {
          setMemberShip(false);
        });
    } else {
      setMemberShip(false);
    }
  }, [user]);

  const tickets = useRest("Planner/Event/Seat/Reserve", {
    Used: "N",
    Status: { $prefix: "reserve_" },
    sort: { Date: "DESC" },
  });

  return (
    <>
      <HelmetComponents>
        <title>
          {t("mypage_title")} - {t("ccommon_meta_title")}
        </title>
        <link
          rel="canonical"
          href={`https://www.yukihayashi.net${getPrefix()}/`}
        />
        <meta
          property="og:title"
          content={`${t("mypage_title")} - ${t("ccommon_meta_title")}`}
        />
        <meta
          property="og:url"
          content={`https://www.yukihayashi.net${getPrefix()}/`}
        />
      </HelmetComponents>

      <div>
        <h2 className="c-ttl-2">
          <span className="c-ttl-2__main">{t("mypage_title")}</span>

          {defaultLng !== "en-US" && (
            <span className="c-ttl-2__sub">
              <span>{t("mypage_sub_title")}</span>
            </span>
          )}
        </h2>

        <div className={styles["mypage-top-wrapper"]}>
          <div className={styles["mypage-user"]}>
            {user === null || (memberShip === null && <Loading />)}
            {user !== null && memberShip !== null && (
              <>
                <p
                  className={styles["mypage-user-hello"]}
                  dangerouslySetInnerHTML={{
                    __html: t("mypage_hello", {
                      name: user.data.Profile.Display_Name,
                    }),
                  }}
                ></p>
                {!memberShip && (
                  <div className={styles["mypage-user-info"]}>
                    <dl>
                      <dt
                        className={`${styles["mypage-user-info-judge"]} ${styles["mypage-user-info-judge--free"]}`}
                        dangerouslySetInnerHTML={{
                          __html: t("mypage_top_member_judge_false"),
                        }}
                      ></dt>
                      <dd className={styles["mypage-user-info-judge-text"]}>
                        <Link
                          className={styles["mypage-user-info-judge-link"]}
                          to="/subscribe"
                        >
                          {t("mypage_top_member_judge_false_text")}
                        </Link>
                      </dd>
                    </dl>
                  </div>
                )}
                {memberShip && (
                  <div className={styles["mypage-user-info"]}>
                    <dl>
                      <dt
                        className={styles["mypage-user-info-judge"]}
                        dangerouslySetInnerHTML={{
                          __html: t("mypage_top_member_judge_true"),
                        }}
                      ></dt>
                      <dd className={styles["mypage-user-info-judge-text"]}>
                        {t("mypage_top_member_judge_true_text")}
                      </dd>
                    </dl>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles["mypage-menu-wrapper"]}>
            <ul className={styles["mypage-menu"]}>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/history"
                >
                  {t("maypage_tab_history")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/user"
                >
                  {t("maypage_tab_user")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/profile"
                >
                  {t("maypage_tab_profile")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/password"
                >
                  {t("maypage_tab_password")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/location"
                >
                  {t("maypage_tab_location")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/billing"
                >
                  {t("maypage_tab_billing")}
                </Link>
              </li>
              <li className={styles["mypage-menu-item"]}>
                <Link
                  className={styles["mypage-menu-item-link"]}
                  to="/mypage/membership"
                >
                  {t("maypage_tab_membership")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {tickets[0] !== null && <Tickets tickets={tickets[0]} />}
      </div>
    </>
  );
};

export default Top;
