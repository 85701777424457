import React from "react";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

// style
import styles from "../Layout.module.scss";

export default ({ children }) => {
  return (
    <div className={styles["layout"]}>
      <Header />

      <div className={`wrapper ${styles["wrapper-insted"]}`}>
        <div className="contents">{children}</div>
      </div>
      <Footer />
    </div>
  );
};
