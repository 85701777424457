import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@material-ui/core";

// component
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";
import EditBilling from "./EditBilling";
import Button from "components/Btns/Btn";
import EditLocation from "./EditLocation";

// styles
import styles from "../../MyPage.module.scss";

// const

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    maxHeight: "100%",
    width: "750px",
    overflowY: "auto",
    "@media all and (max-width: 750px)": {
      width: "100%",
    },
  },
}));

const BillingList = ({
  billingList = {},
  billingRefresh,
  setCurrentPage,
  currentPage,
  membershipBilling,
  changeMembershipBilling,
  deleteBilling,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [currentBilling, setCurrentBilling] = useState(null);

  return (
    <>
      {billingList === null && <Loading />}
      {billingList !== null && billingList?.data.length === 0 && (
        <p className={styles["mypage__notfound"]}>
          {t("mypage_billing_nothing")}
        </p>
      )}
      {billingList !== null && billingList?.data.length !== 0 && (
        <TableContainer>
          <Table className={styles["history-table"]}>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t("mypage_billing_address_label")}
                </TableCell>
                <TableCell align="center">
                  {t("mypage_billing_card_label")}
                </TableCell>
                {membershipBilling !== null && (
                  <TableCell align="center">
                    {t("mypage_billing_membership_label")}
                  </TableCell>
                )}
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingList?.data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {item.User_Location.Display[0]}
                      <br />
                      {item.User_Location.Display[1]}
                      <br />
                      {item.User_Location.Display[2]}
                      <br />
                      {item.User_Location.Display[3] === void 0 ? (
                        ""
                      ) : (
                        <>
                          {item.User_Location.Display[3]}
                          <br />
                        </>
                      )}
                      {item.User_Location.Display[4] === void 0 ? (
                        ""
                      ) : (
                        <>
                          {item.User_Location.Display[4]}
                          <br />
                        </>
                      )}
                      {item.User_Location.Contact_Phone}
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <div
                          className={styles["billing-btn"]}
                          onClick={() => {
                            setCurrentBilling(item);
                            setModalType("locationEdit");
                            setModal(true);
                          }}
                        >
                          {t("mypage_payment_edit")}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {item.Methods[0].Name}
                      <br />
                      {item.Methods[0].Expiration}
                    </TableCell>
                    {membershipBilling !== null && (
                      <TableCell align="center">
                        {membershipBilling === item.User_Billing__ && (
                          <div className={styles["billing-current"]}>
                            {t("mypage_billing_membership_text")}
                          </div>
                        )}
                        {membershipBilling !== item.User_Billing__ && (
                          <div
                            className={`${styles["billing-btn"]} ${styles["billing-btn-change"]}`}
                            onClick={() => {
                              changeMembershipBilling(item.User_Billing__);
                            }}
                          >
                            {t("mypage_billing_membership_btn")}
                          </div>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <div
                        className={styles["billing-btn"]}
                        onClick={() => {
                          setCurrentBilling(item);
                          setModalType("edit");
                          setModal(true);
                        }}
                      >
                        {t("mypage_payment_edit")}
                      </div>
                      {membershipBilling !== item.User_Billing__ && (
                        <div
                          className={`${styles["billing-btn"]} ${styles["billing-btn-delete"]}`}
                          style={{
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            setCurrentBilling(item);
                            setModalType("delete");
                            setModal(true);
                          }}
                        >
                          {t("削除")}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {billingList !== null && billingList?.data.length !== 0 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          defaultPage={currentPage}
          count={billingList?.paging.page_max}
          path="mypage/history"
        />
      )}

      <Modal
        className={classes.modal}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div className={classes.paper}>
          {modalType === "delete" && (
            <>
              <p
                className={styles["member-ship-modal-text-margin"]}
                style={{
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("mypage_location_delete_text"),
                }}
              ></p>
              <div className={styles["member-ship-modal-btns-group"]}>
                <Button
                  onClick={() => {
                    setModal(false);
                  }}
                  iconPosition="noting"
                >
                  {t("mypage_modal_btn_close")}
                </Button>
                <Button
                  onClick={() => {
                    deleteBilling(currentBilling.User_Billing__);
                    setModal(false);
                  }}
                  className={styles["member-ship-btn-delete"]}
                  iconPosition="noting"
                >
                  {t("mypage_location_btn_delete")}
                </Button>
              </div>
            </>
          )}
          {modalType === "edit" && (
            <EditBilling
              billing={currentBilling}
              setEdit={setModal}
              billingRefresh={billingRefresh}
            />
          )}
          {modalType === "locationEdit" && (
            <EditLocation
              billing={currentBilling}
              setEdit={setModal}
              billingRefresh={billingRefresh}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default BillingList;
