import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

// material ui

// component

// style
import styles from "./Payment.module.scss";

const KonbiniDisplay = ({ konbiniInfo }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <dl className={styles["konbini-display"]}>
          <div className={styles["konbini-display-item"]}>
            <dd className={styles["konbini-display-item-name"]}>
              {t("order_payment_title")}
            </dd>
            <dd className={styles["konbini-display-item-body"]}>
              {t("order_payment_konbini")}
            </dd>
          </div>
          <div className={styles["konbini-display-item"]}>
            <dt className={styles["konbini-display-item-name"]}>
              {t("order_payment_expires")}
            </dt>
            <dd className={styles["konbini-display-item-body"]}>
              {moment(
                konbiniInfo.konbini_display_details.expires_at * 1000
              ).format("yyyy年MM月DD日 HH:mm")}
            </dd>
          </div>
        </dl>

        <a
          className="c-btn-3__item mgb-pc--20 mgb-sp--20"
          style={{
            textAlign: "center",
          }}
          href={konbiniInfo.konbini_display_details.hosted_voucher_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("order_payment_btn")}
        </a>
      </div>
    </>
  );
};

export default KonbiniDisplay;
